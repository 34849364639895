import React, { useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Paper, Typography, TextField, Button, Snackbar, Box } from '@mui/material';

const NewsletterSignup = ({ title, description, buttonText }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const dataProvider = useDataProvider();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError('Email is required');
      return false;
    }
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address');
      return false;
    }
    setEmailError('');
    return true;
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;

    if (!validateEmail(email)) return;

    setIsSubmitting(true);
    try {
      const userId = localStorage.getItem('userId') || '00000000-0000-0000-0000-000000000000';

      const response = await dataProvider.create('subscriptions', {
        data: {
          userId,
          email,
          eventType: 'newsletter',
        },
      });

      // Save userId if we got a new one
      const newUserId = response.data?.user_id || response.data?.userId;
      if (newUserId && newUserId !== '00000000-0000-0000-0000-000000000000') {
        localStorage.setItem('userId', newUserId);
      }

      setSnackbar({
        open: true,
        message: 'Thanks for subscribing!',
      });
      setEmail('');
    } catch (error) {
      console.error('Error submitting subscription:', error);
      const errorMessage =
        error.body?.error || error.message || 'Something went wrong. Please try again.';
      setSnackbar({
        open: true,
        message: errorMessage,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        backgroundColor: '#f8f9fa',
        borderRadius: '12px',
        border: '1px solid #dee2e6',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          mb: 2,
          color: '#1a237e',
          fontWeight: 600,
        }}
      >
        {title || 'Stay Updated'}
      </Typography>

      <Typography variant="body1" sx={{ mb: 2 }}>
        {description ||
          'Sign up for our newsletter to know when we release new content, data insights, and features.'}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'flex-start',
        }}
      >
        <TextField
          fullWidth
          label="Your Email"
          variant="outlined"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            if (emailError) validateEmail(e.target.value);
          }}
          error={!!emailError}
          helperText={emailError}
          size="small"
          sx={{ flexGrow: 1 }}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={!email || isSubmitting}
          onClick={handleSubmit}
          sx={{
            minWidth: '120px',
            height: '40px',
          }}
        >
          {buttonText || 'Subscribe'}
        </Button>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Paper>
  );
};

export default NewsletterSignup;
