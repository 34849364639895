import React, { useState, useEffect } from 'react';
import { useGetList, Show, useRecordContext, useShowController } from 'react-admin';
import {
  Grid,
  Typography,
  Paper,
  Box,
  Chip,
  Button,
  TextField,
  Snackbar,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PageBreadcrumbs } from '../utils/StyledBreadcrumbs';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useDataProvider } from 'react-admin';
import AdPlaceholder from '../utils/AdPlaceholder';
import LoadingScreen from '../utils/LoadingScreen';
import { alpha } from '@mui/material/styles';

// Update ContentLayout to use width constraints
const ContentLayout = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing(2),
  padding: { xs: theme.spacing(1), sm: theme.spacing(2) },
  width: '100%',
  maxWidth: '100%',
  margin: '0 auto',
  overflow: 'hidden',
  boxSizing: 'border-box',
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '300px 1fr',
    padding: theme.spacing(3),
    maxWidth: '100%',
    gap: theme.spacing(3),
  },
}));

const ProfileWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  margin: '0 auto',
  padding: 0,
  position: 'relative',
  background: '#fff',
  borderBottom: '1px solid #E0E0E0',
  overflow: 'hidden',
  boxSizing: 'border-box',
  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
  },
}));

const PlayerImage = () => {
  const record = useRecordContext();
  if (!record || !record.data || !record.data.sleeper_id) return null;

  return (
    <img
      src={`/images/2025_rookie_images/${record.data.sleeper_id}.png`}
      alt="Player"
      onError={(e) => {
        e.target.src = '/light_taco.png';
      }}
      style={{
        width: '120px',
        height: '120px',
        borderRadius: '8px',
        objectFit: 'cover',
        border: '2px solid #e0e0e0',
      }}
    />
  );
};

const PlayerInfo = () => {
  const record = useRecordContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!record || !record.data) {
    return (
      <Box sx={{ height: '100vh' }}>
        <LoadingScreen message="Loading player profile..." />
      </Box>
    );
  }

  const playerData = {
    ...record.data,
  };

  const getExperienceLabel = (years_exp) => {
    if (years_exp === 0) return 'Rookie';
    if (years_exp === 1) return 'Sophomore';
    return `${years_exp} years`;
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const formatHeight = (inches) => {
    if (!inches) return 'N/A';
    const feet = Math.floor(inches / 12);
    const remainingInches = inches % 12;
    return `${feet}'${remainingInches}"`;
  };

  const calculateAge = (birthDate) => {
    if (!birthDate) return 'N/A';
    const birth = new Date(birthDate);
    const today = new Date();
    const diffTime = Math.abs(today - birth);
    const age = diffTime / (1000 * 60 * 60 * 24 * 365.25); // Using 365.25 to account for leap years
    return age.toFixed(1);
  };

  const getPlayerTeamAndNumber = (playerData) => {
    let returnValue = '';
    const currentYear = new Date().getFullYear();

    if (playerData.nfl_team) {
      returnValue += playerData.nfl_team;
    }

    if (playerData.number) {
      returnValue += ` #${playerData.number}`;
    } else if (playerData.years_exp === 0) {
      returnValue += ` ${currentYear} Prospect`;
    }

    return returnValue.trim();
  };

  const Tweet = ({ embedCode }) => {
    const tweetRef = React.useRef(null);

    React.useEffect(() => {
      // Remove any existing tweet widgets
      if (tweetRef.current) {
        tweetRef.current.innerHTML = embedCode;
      }

      // Load Twitter widget script if it hasn't been loaded
      if (!window.twttr) {
        const script = document.createElement('script');
        script.src = 'https://platform.twitter.com/widgets.js';
        script.async = true;
        script.charset = 'utf-8';
        document.body.appendChild(script);

        script.onload = () => {
          if (window.twttr) {
            window.twttr.widgets.load(tweetRef.current);
          }
        };
      } else {
        // If script is already loaded, just render the tweet
        window.twttr.widgets.load(tweetRef.current);
      }
    }, [embedCode]);

    return (
      <Box
        ref={tweetRef}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          '& .twitter-tweet': {
            margin: '0 !important',
          },
          minHeight: '300px', // Add minimum height to prevent layout shift
        }}
      />
    );
  };

  // Update the ContentCard styled component to use theme colors
  const ContentCard = styled(Paper)(({ theme }) => ({
    padding: { xs: theme.spacing(2), sm: theme.spacing(3) },
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: { xs: 0, sm: '12px' },
    boxShadow: '0 6px 24px rgba(0, 0, 0, 0.15)',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.secondary.light}`, // Using theme.palette.primary.light for border
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: '0 12px 30px rgba(0, 0, 0, 0.25)',
      backgroundColor: theme.palette.background.paper,
    },
    '& h6': {
      color: theme.palette.primary.light, // Light primary color for headings for contrast
      borderBottom: `2px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
      paddingBottom: '12px',
      marginBottom: '20px',
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    '& p': {
      lineHeight: 1.8,
      color: '#ffffff', // White text for readability on black
      fontSize: '1.05rem',
    },
  }));

  // Update the DeepDiveHeader to ensure it doesn't have any unwanted styling
  const DeepDiveHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    padding: 0,
    '&::before, &::after': {
      content: '""',
      flex: 1,
      height: '2px',
      background: 'linear-gradient(to right, rgba(25, 118, 210, 0.1), rgba(25, 118, 210, 0.5))',
    },
    '&::before': {
      marginRight: theme.spacing(2),
    },
    '&::after': {
      marginLeft: theme.spacing(2),
    },
  }));

  // Update the renderPlayerContent function to enhance content display
  const renderPlayerContent = (content) => {
    return (
      <>
        {content.title && (
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              ml: 1,
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: '-12px',
                width: '40px',
                height: '3px',
              },
            }}
          >
            {content.title}
          </Typography>
        )}
        {content.description && (
          <Typography
            variant="body1"
            sx={{
              mb: 3,
              color: 'text.secondary',
              fontStyle: 'italic',
              borderLeft: '3px solid #e3f2fd',
              paddingLeft: 2,
              marginLeft: -1,
            }}
          >
            {content.description}
          </Typography>
        )}
        {(() => {
          switch (content.type) {
            case 'youtube':
              const videoId = content.content.split('v=')[1];
              return (
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    maxWidth: '800px',
                    margin: '0 auto 24px auto',
                    borderRadius: '12px',
                    overflow: 'hidden',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Box
                    sx={{
                      paddingTop: '56.25%', // 16:9 aspect ratio
                      position: 'relative',
                      height: 0,
                      backgroundColor: '#000',
                    }}
                  >
                    <iframe
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: 'none',
                      }}
                      src={`https://www.youtube.com/embed/${videoId}`}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </Box>
                </Box>
              );
            case 'twitter':
              return (
                <Box
                  sx={{
                    my: 3,
                    mx: 'auto',
                    maxWidth: '550px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
                    borderRadius: '12px',
                    overflow: 'hidden',
                  }}
                >
                  <Tweet embedCode={content.content} />
                </Box>
              );
            case 'quote':
              return (
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    backgroundColor: '#f8f9fa',
                    borderLeft: '4px solid #1976d2',
                    borderRadius: '4px',
                    my: 3,
                    position: 'relative',
                    '&::before': {
                      content: '"""',
                      position: 'absolute',
                      top: '-10px',
                      left: '10px',
                      fontSize: '3rem',
                      color: 'rgba(25, 118, 210, 0.2)',
                      fontFamily: 'Georgia, serif',
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontStyle: 'italic',
                      fontSize: '1.1rem',
                      lineHeight: 1.6,
                      position: 'relative',
                      zIndex: 1,
                    }}
                  >
                    "{content.content}"
                  </Typography>
                </Paper>
              );
            default:
              return null;
          }
        })()}
      </>
    );
  };

  const ContentRequestSection = ({ playerData }) => {
    const [email, setEmail] = useState('');
    const [suggestion, setSuggestion] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '' });
    const [activeTab, setActiveTab] = useState(0);
    const dataProvider = useDataProvider();

    useEffect(() => {
      return () => {
        setIsSubmitting(false);
        setSnackbar({ open: false, message: '' });
      };
    }, []);

    const handleSubmit = async (type) => {
      if (isSubmitting) return;

      setIsSubmitting(true);
      try {
        let userId = localStorage.getItem('userId') || '00000000-0000-0000-0000-000000000000';
        let response;

        const requestData = {
          data: {
            playerId: playerData.id,
            userId,
            email,
            ...(type === 'suggest' && { content: suggestion }),
            ...(type !== 'suggest' && { eventType: type === 'notify' ? 'content_update' : type }),
          },
        };

        const endpoint = type === 'suggest' ? 'player_contents' : 'subscriptions';
        response = await dataProvider.create(endpoint, requestData);

        const newUserId = response.data?.user_id || response.data?.userId;
        if (newUserId && newUserId !== '00000000-0000-0000-0000-000000000000') {
          localStorage.setItem('userId', newUserId);
        }

        const messages = {
          request_more: "We're working on it!",
          request_coverage: "Thanks for your vote – we're adding content as fast as we can!",
          notify: 'You will be notified when more content is added!',
          suggest: 'Thanks for your suggestion!',
        };
        setSnackbar({ open: true, message: messages[type] });

        setEmail('');
        setSuggestion('');
      } catch (error) {
        console.error('Error submitting request:', error);
        const errorMessage =
          error.body?.error || error.message || 'Something went wrong. Please try again.';
        setSnackbar({
          open: true,
          message: errorMessage,
        });
      } finally {
        setIsSubmitting(false);
      }
    };

    const handleCloseSnackbar = () => {
      setSnackbar({ ...snackbar, open: false });
    };

    return (
      <>
        <Paper
          elevation={0}
          sx={{
            mt: 3,
            p: { xs: 2, sm: 3 },
            backgroundColor: '#f8f9fa',
            borderRadius: { xs: 0, sm: '12px' },
            border: '1px solid #dee2e6',
            width: '100%',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              color: '#1a237e',
              fontWeight: 600,
            }}
          >
            {playerData.player_contents?.length > 0
              ? 'Want More Content?'
              : `Request Content for ${playerData.name}`}
          </Typography>

          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            sx={{
              mb: 3,
              borderBottom: 1,
              borderColor: 'divider',
              '& .MuiTab-root': {
                textTransform: 'none',
                fontSize: '0.9rem',
                minHeight: '48px',
              },
            }}
          >
            <Tab label="Get Notifications" />
            <Tab label="Suggest Content" />
          </Tabs>

          {/* Tab 1: Get Notifications */}
          {activeTab === 0 && (
            <Box sx={{ p: 2 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {playerData.player_contents?.length > 0
                  ? `Be the first to know when new content is added for ${playerData.name}.`
                  : `Want us to create content next for ${playerData.name}? Optionally add your email to be notified when we do!`}
              </Typography>
              <TextField
                fullWidth
                label={`Your Email${playerData.player_contents?.length > 0 ? '' : ' (Optional)'}`}
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2 }}
                size="small"
                required={playerData.player_contents?.length > 0}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={() =>
                  handleSubmit(
                    playerData.player_contents?.length > 0 ? 'notify' : 'request_coverage'
                  )
                }
                fullWidth
              >
                {playerData.player_contents?.length > 0
                  ? 'Notify Me of Updates'
                  : 'Request More Content'}
              </Button>
            </Box>
          )}

          {/* Tab 2: Suggest Content */}
          {activeTab === 1 && (
            <Box sx={{ p: 2 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Have a great piece of content about {playerData.name}? Share it with us!
              </Typography>
              <TextField
                fullWidth
                label="Your Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2 }}
                size="small"
              />
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Share links to articles, videos, or other content"
                variant="outlined"
                value={suggestion}
                onChange={(e) => setSuggestion(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                color="secondary"
                disabled={!suggestion || !email || isSubmitting}
                onClick={() => handleSubmit('suggest')}
                fullWidth
              >
                Submit Suggestion
              </Button>
            </Box>
          )}
        </Paper>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
          message={snackbar.message}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      </>
    );
  };

  return (
    <Box sx={{ p: { xs: 0, sm: 2 } }}>
      <Grid container spacing={{ xs: 1, sm: 3 }}>
        <Grid item xs={12} md={3}>
          <Box sx={{ textAlign: 'center' }}>
            <PlayerImage />
            <Typography variant="h5" sx={{ mt: 2, fontWeight: 500, color: '#212529' }}>
              {playerData.name}
            </Typography>
            <Chip
              label={`${getPlayerTeamAndNumber(playerData)}`}
              sx={{
                mt: 1,
                backgroundColor: '#e9ecef',
                color: '#495057',
                fontWeight: 500,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper
            elevation={0}
            sx={{
              p: 3,
              backgroundColor: '#f8f9fa',
              borderRadius: '8px',
              border: '1px solid #e0e0e0',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={4} sx={{ textAlign: { xs: 'left', sm: 'left' } }}>
                <Typography variant="subtitle2" color="textSecondary">
                  Position
                </Typography>
                <Typography variant="body1">{playerData.position}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={4} sx={{ textAlign: { xs: 'left', sm: 'left' } }}>
                <Typography variant="subtitle2" color="textSecondary">
                  Experience
                </Typography>
                <Typography variant="body1">{getExperienceLabel(playerData.years_exp)}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={4} sx={{ textAlign: { xs: 'left', sm: 'left' } }}>
                <Typography variant="subtitle2" color="textSecondary">
                  Height
                </Typography>
                <Typography variant="body1">{formatHeight(playerData.height)}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={4} sx={{ textAlign: { xs: 'left', sm: 'left' } }}>
                <Typography variant="subtitle2" color="textSecondary">
                  Birth Date
                </Typography>
                <Typography variant="body1">{formatDate(playerData.dob)}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={4} sx={{ textAlign: { xs: 'left', sm: 'left' } }}>
                <Typography variant="subtitle2" color="textSecondary">
                  Age
                </Typography>
                <Typography variant="body1">{calculateAge(playerData.dob)}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={4} sx={{ textAlign: { xs: 'left', sm: 'left' } }}>
                <Typography variant="subtitle2" color="textSecondary">
                  Weight
                </Typography>
                <Typography variant="body1">{playerData.weight || 'N/A'} lbs</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={4} sx={{ textAlign: { xs: 'left', sm: 'left' } }}>
                <Typography variant="subtitle2" color="textSecondary">
                  College
                </Typography>
                <Typography variant="body1">{playerData.college || 'N/A'}</Typography>
              </Grid>
            </Grid>
          </Paper>

          {playerData.injury_status && (
            <>
              <Grid item xs={12}>
                <AdPlaceholder type="horizontal" />
              </Grid>
              <Grid item xs={12}>
                <Paper
                  elevation={0}
                  sx={{
                    mt: 3,
                    p: 3,
                    backgroundColor: '#fff3f3',
                    borderRadius: '8px',
                    border: '1px solid #ffcdd2',
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ mb: 2, color: '#d32f2f', textAlign: { xs: 'left', sm: 'left' } }}
                  >
                    Injury Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={4} sx={{ textAlign: { xs: 'left', sm: 'left' } }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Status
                      </Typography>
                      <Typography variant="body1">{playerData.injury_status}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} sx={{ textAlign: { xs: 'left', sm: 'left' } }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Body Part
                      </Typography>
                      <Typography variant="body1">
                        {playerData.injury_body_part || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: { xs: 'left', sm: 'left' } }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Notes
                      </Typography>
                      <Typography variant="body1">{playerData.injury_notes || 'N/A'}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </>
          )}
        </Grid>
        {playerData.years_exp === 0 && (
          <>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      backgroundColor: '#f8f9fa',
                      borderRadius: '8px',
                      border: '1px solid #e0e0e0',
                      textAlign: 'center',
                      height: { xs: '120px', sm: '140px' },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                      Projected NFL Draft
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 500,
                        fontSize: { xs: '1.2rem', sm: '1.5rem' },
                        lineHeight: 1.2,
                        wordBreak: 'break-word',
                      }}
                    >
                      {playerData.projected_draft_round
                        ? `${playerData.projected_draft_round}`
                        : 'Undrafted'}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      backgroundColor: '#f8f9fa',
                      borderRadius: '8px',
                      border: '1px solid #e0e0e0',
                      textAlign: 'center',
                      height: { xs: '120px', sm: '140px' },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                      SF Rookie Draft Rank
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 500,
                        fontSize: { xs: '1.2rem', sm: '1.5rem' },
                        lineHeight: 1.2,
                        wordBreak: 'break-word',
                      }}
                    >
                      #{playerData.sf_rookie_rank || 'N/A'}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      backgroundColor: '#f8f9fa',
                      borderRadius: '8px',
                      border: '1px solid #e0e0e0',
                      textAlign: 'center',
                      height: { xs: '120px', sm: '140px' },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                      Rookie Positional Rank
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 500,
                        fontSize: { xs: '1.2rem', sm: '1.5rem' },
                        lineHeight: 1.2,
                        wordBreak: 'break-word',
                      }}
                    >
                      {playerData.rookie_position_rank
                        ? `${playerData.position}${playerData.rookie_position_rank}`
                        : 'N/A'}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {/* Single ad placement after rookie content - Add maxWidth for better mobile display */}
        {playerData.years_exp === 0 && (
          <Grid item xs={12}>
            <Box
              sx={{
                mt: 3,
                width: '100%',
                maxWidth: '100%',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <AdPlaceholder type="horizontal" />
            </Box>
          </Grid>
        )}
        {playerData.player_contents && playerData.player_contents.length > 0 && (
          <>
            <Grid item xs={12}>
              <Paper
                elevation={0}
                sx={{
                  mt: 3,
                  p: { xs: 2, sm: 3 },
                  backgroundColor: '#f8f9fa',
                  borderRadius: { xs: 0, sm: '12px' },
                  border: '1px solid #dee2e6',
                  overflow: 'hidden', // Ensure nothing spills out
                  position: 'relative', // For proper positioning
                }}
              >
                <DeepDiveHeader>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 700,
                      color: '#1a237e',
                      textTransform: 'uppercase',
                      letterSpacing: '1px',
                      fontSize: { xs: '1.25rem', sm: '1.5rem' },
                      textAlign: 'center',
                      background: 'linear-gradient(45deg, #1a237e, #3949ab)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    Deep Dive Analysis
                  </Typography>
                </DeepDiveHeader>

                {playerData.player_contents.map((content, index) => (
                  <React.Fragment key={index}>
                    <ContentCard>{renderPlayerContent(content)}</ContentCard>

                    {/* Hide every other ad on mobile to reduce clutter */}
                    {(index % 2 === 0 || !isMobile) && (
                      <Box
                        sx={{
                          my: 3,
                          width: '100%',
                          maxWidth: '100%',
                          overflow: 'hidden',
                          display: 'flex',
                          justifyContent: 'center',
                          opacity: 0.8, // Make ads slightly less prominent
                        }}
                      >
                        <AdPlaceholder type="horizontal" />
                      </Box>
                    )}
                  </React.Fragment>
                ))}

                <ContentRequestSection playerData={playerData} />

                {/* Only show this ad on desktop */}
                <Box
                  sx={{
                    mt: 3,
                    width: '100%',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'center',
                  }}
                >
                  <AdPlaceholder type="horizontal" />
                </Box>
              </Paper>
            </Grid>
          </>
        )}
        {!playerData.player_contents?.length && (
          <>
            <Grid item xs={12}>
              <ContentRequestSection playerData={playerData} />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  mt: 3,
                  width: '100%',
                  maxWidth: '100%',
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <AdPlaceholder type="horizontal" />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

const PlayerTitle = () => {
  const record = useRecordContext();
  return <span>{record ? record.data.name : ''}</span>;
};

const SortablePlayer = ({ player, currentPlayer, index }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: player.id,
    animateLayoutChanges: () => false,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 1,
    width: '100%',
  };

  return (
    <Paper
      ref={setNodeRef}
      style={style}
      elevation={isDragging ? 4 : 0}
      sx={{
        py: 1,
        px: { xs: 1, sm: 2 },
        backgroundColor: player.id === currentPlayer.id ? '#e3f2fd' : 'white',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: { xs: 1, sm: 2 },
        position: 'relative',
        opacity: isDragging ? 0.5 : 1,
        height: '100%',
        touchAction: 'pan-y',
        userSelect: 'none',
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: { xs: 1, sm: 2 },
          flex: 1,
          minWidth: 0,
          touchAction: 'auto',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            width: { xs: '20px', sm: '28px' },
            height: { xs: '20px', sm: '28px' },
            borderRadius: '50%',
            backgroundColor: '#e0e0e0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            color: '#424242',
            fontSize: { xs: '0.7rem', sm: '0.9rem' },
            flexShrink: 0,
          }}
        >
          {index + 1}
        </Typography>
        <img
          src={`/images/2025_rookie_images/${player.sleeper_id}.png`}
          alt={player.name}
          onError={(e) => {
            e.target.src = '/light_taco.png';
          }}
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '4px',
            objectFit: 'cover',
            flexShrink: 0,
          }}
        />
        <Box sx={{ flexGrow: 1, minWidth: 0 }}>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 500,
              lineHeight: 1.2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: { xs: '0.75rem', sm: '0.875rem' },
            }}
          >
            {player.name}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{
              display: 'block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: { xs: '0.65rem', sm: '0.75rem' },
            }}
          >
            {player.position}
            {index + 1}
          </Typography>
        </Box>
      </Box>

      <Box
        {...attributes}
        {...listeners}
        sx={{
          width: '44px',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'grab',
          touchAction: 'none',
          '&:active': { cursor: 'grabbing' },
          ml: 'auto',
          borderLeft: '1px solid #e0e0e0',
          backgroundColor: '#f8f9fa',
          borderRadius: '0 8px 8px 0',
          '&::before': {
            content: '"≡"',
            fontSize: '24px',
            color: '#9e9e9e',
            fontWeight: 'bold',
          },
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
        }}
      />
    </Paper>
  );
};

const getRankingType = (player) => {
  if (!player || !player.data) return null;

  let rankingType = '2025-rankings';
  if (player.data.years_exp === 0) {
    rankingType = '2025-prospects';
    if (player.data.position === 'QB') {
      rankingType = '2025-prospects-QB';
    } else if (player.data.position === 'RB') {
      rankingType = '2025-prospects-RB';
    } else if (player.data.position === 'WR') {
      rankingType = '2025-prospects-WR';
    } else if (player.data.position === 'TE') {
      rankingType = '2025-prospects-TE';
    }
  }
  return rankingType;
};

const SimilarPlayers = () => {
  const currentPlayer = useRecordContext();
  const rankingType = getRankingType(currentPlayer);
  const [items, setItems] = React.useState([]);
  const dataProvider = useDataProvider();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 500,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  let similarPlayers;
  const localStorageUserId =
    localStorage.getItem('userId') || '00000000-0000-0000-0000-000000000000';
  const { data, isLoading } = useGetList(`users/${localStorageUserId}/rankings`, {
    pagination: { page: 1, perPage: 10 },
    filter: { rankingType: rankingType },
  });
  similarPlayers = data;

  React.useEffect(() => {
    if (similarPlayers) {
      setItems(Object.values(similarPlayers));
    }
  }, [similarPlayers]);

  if (!currentPlayer?.data || isLoading || !similarPlayers) return null;

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const newItems = arrayMove(items, oldIndex, newIndex);

        const userId = localStorage.getItem('userId') || '00000000-0000-0000-0000-000000000000';

        dataProvider
          .create(`rankings/${userId}`, {
            data: {
              rankings: newItems.map((item, index) => ({
                playerId: item.id,
                rank: index + 1,
              })),
              rankingType: rankingType,
            },
          })
          .then((response) => {
            if (response.data.user_id) {
              localStorage.setItem('userId', response.data.user_id);
            }
          })
          .catch((error) => {
            console.error('Error saving rankings:', error);
            setItems((prevItems) => arrayMove(prevItems, newIndex, oldIndex));
          });

        return newItems;
      });
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        overflowX: 'hidden',
        boxSizing: 'border-box',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <AdPlaceholder type="horizontal" />
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            sx={{
              backgroundColor: '#f8f9fa',
              borderRadius: '8px',
              border: '1px solid #e0e0e0',
              p: { xs: 1, sm: 2 },
              mt: 2,
              width: '100%',
              maxWidth: '100%',
              boxSizing: 'border-box',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 0.5,
                fontSize: { xs: '1.1rem', sm: '1.25rem' },
              }}
            >
              Your Positional Rankings
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                mb: { xs: 1, sm: 2 },
                fontSize: { xs: '0.8rem', sm: '0.875rem' },
              }}
            >
              Drag using the handle on the right to reorder players
            </Typography>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={items.map((item) => item.id)}
                strategy={verticalListSortingStrategy}
              >
                <Grid
                  container
                  spacing={1}
                  sx={{
                    maxHeight: { xs: '60vh', sm: 'none' },
                    overflow: { xs: 'auto', sm: 'visible' },
                    overflowX: 'hidden',
                    pb: { xs: 2, sm: 0 },
                    width: '100%',
                    margin: 0,
                    scrollbarWidth: 'thin',
                    '&::-webkit-scrollbar': {
                      width: '6px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: '#f1f1f1',
                      borderRadius: '3px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#888',
                      borderRadius: '3px',
                    },
                  }}
                >
                  {items.map((player, index) => (
                    <Grid
                      item
                      xs={12}
                      key={player.id}
                      sx={{
                        width: '100%',
                        pl: 0,
                        pr: 0,
                      }}
                    >
                      <SortablePlayer
                        player={player}
                        currentPlayer={currentPlayer.data}
                        index={index}
                      />
                    </Grid>
                  ))}
                </Grid>
              </SortableContext>
            </DndContext>
          </Paper>
          <Grid item xs={12} sx={{ mt: 4 }}>
            <AdPlaceholder type="horizontal" />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export const PlayerShow = () => {
  const { record, isLoading, error } = useShowController();
  if (error) return null;

  if (isLoading || !record || !record.data) {
    return (
      <Box>
        <ProfileWrapper sx={{ height: '100vh' }}>
          <ContentLayout>
            <LoadingScreen message="Loading player profile..." />
          </ContentLayout>
        </ProfileWrapper>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: '100%',
        overflowX: 'hidden',
        position: 'relative',
        '& > *': {
          maxWidth: '100%',
        },
      }}
    >
      <PageBreadcrumbs />
      <Show id={record.id}>
        <Box
          sx={{
            width: '100%',
            maxWidth: '100%',
            overflowX: 'hidden',
            px: { xs: 0, sm: 2 },
            boxSizing: 'border-box',
          }}
        >
          <Grid
            container
            spacing={{ xs: 0, sm: 2 }}
            sx={{
              width: '100%',
              m: 0,
              p: 0,
              '& > .MuiGrid-item': {
                p: { xs: 0, sm: 1 },
                width: '100%',
              },
            }}
          >
            <Grid item xs={12} md={8}>
              <PlayerInfo />
            </Grid>
            <Grid item xs={12} md={4}>
              <SimilarPlayers />
            </Grid>
          </Grid>
        </Box>
      </Show>
    </Box>
  );
};
