import React, { useEffect, useRef } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Link,
  Avatar,
  TextField,
  Button,
  Snackbar,
  Alert,
  IconButton,
  Container,
} from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import { useDataProvider, Title } from 'react-admin';
import NewsletterSignup from './NewsletterSignup';
import { PageBreadcrumbs } from '../utils/StyledBreadcrumbs';

const AboutPage = () => {
  const [flippedCard, setFlippedCard] = React.useState(null);
  const [nomination, setNomination] = React.useState('');
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [isRevealed, setIsRevealed] = React.useState(false);
  const [showFlowers, setShowFlowers] = React.useState(false);
  const hofRef = useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);
  const dataProvider = useDataProvider();

  // Create flower elements with random positions
  const flowers = React.useMemo(
    () =>
      Array.from({ length: 15 }).map((_, i) => ({
        id: i,
        left: 10 + Math.random() * 80, // Keep away from edges
        delay: Math.random() * 0.8,
        duration: 1.5 + Math.random() * 1,
        rotation: -30 + Math.random() * 60, // More natural throw angle
        swing: 20 + Math.random() * 30,
        throwHeight: {
          xs: 400 + Math.random() * 400, // Higher throw on mobile
          sm: 200 + Math.random() * 300, // Original height for desktop
        },
      })),
    []
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.2, // Show when 20% of the element is visible
        rootMargin: '-50px', // Trigger slightly before the element comes into view
      }
    );

    if (hofRef.current) {
      observer.observe(hofRef.current);
    }

    return () => observer.disconnect();
  }, []);

  // Trigger flowers after curtain animation
  useEffect(() => {
    if (isRevealed) {
      const timer = setTimeout(() => {
        setShowFlowers(true);
      }, 1200); // Match curtain animation duration
      return () => clearTimeout(timer);
    }
  }, [isRevealed]);

  const handleSubmitNomination = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const userId = localStorage.getItem('userId');

    try {
      const response = await dataProvider.create('subscriptions', {
        data: {
          userId,
          eventType: 'hof_nomination',
          extraData: {
            nomination,
          },
        },
      });

      // Save userId from response if not in localStorage
      if (!userId && response.data?.userId) {
        localStorage.setItem('userId', response.data.userId);
      }

      setNomination('');
      setFlippedCard(null);
      setSuccessOpen(true);
    } catch (error) {
      console.error('Error submitting nomination:', error);
      // Could add error handling here
    }
  };

  return (
    <Box>
      <PageBreadcrumbs />
      <Box sx={{ margin: 'auto', mt: 2, px: 3, pb: 4 }}>
        <Title title="About" />

        <Grid container spacing={4} sx={{ mt: 1 }}>
          {/* Why I Built This */}
          <Grid item xs={12}>
            <Card elevation={3} sx={{ height: '100%', borderRadius: 2 }}>
              <CardContent>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ color: 'primary.main', fontWeight: 'bold' }}
                >
                  Why I Built This 🛠️
                </Typography>
                <Typography variant="body1" paragraph>
                  I'm the commish of a dynasty league and recently had someone bail on me (partially
                  because their team sucked and partially because they didn't know what they were
                  getting into when they joined).
                </Typography>
                <Typography variant="body1" paragraph>
                  Letting someone into your dynasty league is a big deal and I wanted to get someone
                  who would be active, competitive, and not bail on me in a year.
                </Typography>
                <Typography variant="body1" paragraph>
                  There are lots of other great dynasty tools out there, but it takes a lot of
                  effort to figure out whether someone has abandoned leagues. I wanted a snapshot of
                  a manager so I could quickly get a sense of whether that was the kind of manager
                  I'd like to have in the league. So V1 of this was just for me, but it didn't take
                  long for me to realize that other people might like it too.
                </Typography>
                <Typography variant="body1" paragraph>
                  Also I'm obsessed with prospects (and rookie picks), so I added a section for
                  prospects. I'll keep building new features that are missing in the dynasty
                  community, so stay tuned and let me know what you'd like to see!
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Hall of Fame */}
          <Grid item xs={12}>
            <Box
              ref={hofRef}
              sx={{
                position: 'relative',
                minHeight: '600px',
                overflow: 'hidden',
              }}
            >
              {/* Falling Flowers */}
              {showFlowers &&
                flowers.map((flower) => (
                  <Box
                    key={flower.id}
                    sx={{
                      position: 'absolute',
                      bottom: -50,
                      left: `${flower.left}%`,
                      fontSize: '2rem',
                      zIndex: 4,
                      opacity: 0,
                      animation: `
                        throwUp ${flower.duration}s cubic-bezier(0.4, 0, 0.2, 1) ${
                        flower.delay
                      }s forwards,
                        spin ${flower.duration}s linear ${flower.delay}s forwards,
                        sway ${flower.duration / 2}s ease-in-out ${
                        flower.delay
                      }s infinite alternate,
                        fadeInOut ${flower.duration}s ease-in-out ${flower.delay}s forwards
                      `,
                      '@keyframes throwUp': {
                        '0%': {
                          transform: 'translateY(0) scale(0.8)',
                        },
                        '50%': {
                          transform: `translateY(-${flower.throwHeight.xs}px) scale(1.1)`,
                          '@media (min-width: 600px)': {
                            transform: `translateY(-${flower.throwHeight.sm}px) scale(1.1)`,
                          },
                        },
                        '100%': {
                          transform: `translateY(-${flower.throwHeight.xs * 0.7}px) scale(1)`,
                          '@media (min-width: 600px)': {
                            transform: `translateY(-${flower.throwHeight.sm * 0.7}px) scale(1)`,
                          },
                        },
                      },
                      '@keyframes spin': {
                        '0%': {
                          rotate: '0deg',
                        },
                        '100%': {
                          rotate: `${flower.rotation}deg`,
                        },
                      },
                      '@keyframes sway': {
                        '0%': {
                          marginLeft: `-${flower.swing}px`,
                        },
                        '100%': {
                          marginLeft: `${flower.swing}px`,
                        },
                      },
                      '@keyframes fadeInOut': {
                        '0%': { opacity: 0 },
                        '20%': { opacity: 1 },
                        '80%': { opacity: 1 },
                        '100%': { opacity: 0 },
                      },
                    }}
                  >
                    💐
                  </Box>
                ))}

              {/* Left Curtain */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '50%',
                  height: '100%',
                  background: 'linear-gradient(135deg, #8B0000 0%, #DC143C 100%)',
                  transform: isRevealed ? 'translateX(-100%)' : 'translateX(0)',
                  transition: 'transform 1.2s cubic-bezier(0.4, 0, 0.2, 1)',
                  zIndex: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  boxShadow: '2px 0px 10px rgba(0,0,0,0.3)',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '100px',
                    background: 'linear-gradient(180deg, rgba(0,0,0,0.4) 0%, transparent 100%)',
                    zIndex: 1,
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    right: 0,
                    height: '100%',
                    width: '20px',
                    background: 'linear-gradient(to right, transparent, rgba(218, 165, 32, 0.2))',
                  },
                  // Enhanced curtain texture with multiple layers
                  backgroundImage: `
                    linear-gradient(135deg, #8B0000 0%, #DC143C 100%),
                    repeating-linear-gradient(
                      0deg,
                      transparent,
                      transparent 10px,
                      rgba(0, 0, 0, 0.1) 10px,
                      rgba(0, 0, 0, 0.1) 20px
                    ),
                    repeating-linear-gradient(
                      90deg,
                      transparent,
                      transparent 40px,
                      rgba(0, 0, 0, 0.03) 40px,
                      rgba(0, 0, 0, 0.03) 80px
                    ),
                    linear-gradient(
                      90deg,
                      rgba(255, 255, 255, 0.05) 0px,
                      transparent 1px,
                      rgba(255, 255, 255, 0.05) 2px,
                      transparent 3px
                    )
                  `,
                  backgroundBlendMode: 'soft-light, overlay, soft-light, normal',
                  // Add swag decoration at the top
                  '&>div': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '50px',
                    background: `
                      radial-gradient(
                        circle at 50% 100%,
                        transparent 20px,
                        #8B0000 21px,
                        #DC143C 30px,
                        #8B0000 31px,
                        transparent 32px
                      )
                    `,
                    backgroundSize: '100px 50px',
                    backgroundPosition: '50% 0',
                    backgroundRepeat: 'repeat-x',
                    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.3))',
                  },
                }}
              >
                <div /> {/* Swag decoration element */}
              </Box>

              {/* Right Curtain */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: '50%',
                  height: '100%',
                  background: 'linear-gradient(225deg, #8B0000 0%, #DC143C 100%)',
                  transform: isRevealed ? 'translateX(100%)' : 'translateX(0)',
                  transition: 'transform 1.2s cubic-bezier(0.4, 0, 0.2, 1)',
                  zIndex: 2,
                  boxShadow: '-2px 0px 10px rgba(0,0,0,0.3)',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '100px',
                    background: 'linear-gradient(180deg, rgba(0,0,0,0.4) 0%, transparent 100%)',
                    zIndex: 1,
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    height: '100%',
                    width: '20px',
                    background: 'linear-gradient(to left, transparent, rgba(218, 165, 32, 0.2))',
                  },
                  // Enhanced curtain texture with multiple layers
                  backgroundImage: `
                    linear-gradient(225deg, #8B0000 0%, #DC143C 100%),
                    repeating-linear-gradient(
                      0deg,
                      transparent,
                      transparent 10px,
                      rgba(0, 0, 0, 0.1) 10px,
                      rgba(0, 0, 0, 0.1) 20px
                    ),
                    repeating-linear-gradient(
                      90deg,
                      transparent,
                      transparent 40px,
                      rgba(0, 0, 0, 0.03) 40px,
                      rgba(0, 0, 0, 0.03) 80px
                    ),
                    linear-gradient(
                      90deg,
                      rgba(255, 255, 255, 0.05) 0px,
                      transparent 1px,
                      rgba(255, 255, 255, 0.05) 2px,
                      transparent 3px
                    )
                  `,
                  backgroundBlendMode: 'soft-light, overlay, soft-light, normal',
                  // Add swag decoration at the top
                  '&>div': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '50px',
                    background: `
                      radial-gradient(
                        circle at 50% 100%,
                        transparent 20px,
                        #8B0000 21px,
                        #DC143C 30px,
                        #8B0000 31px,
                        transparent 32px
                      )
                    `,
                    backgroundSize: '100px 50px',
                    backgroundPosition: '50% 0',
                    backgroundRepeat: 'repeat-x',
                    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.3))',
                  },
                }}
              >
                <div /> {/* Swag decoration element */}
              </Box>

              {/* Reveal Button */}
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: `translate(-50%, -50%) scale(${isRevealed ? 0 : 1})`,
                  zIndex: 3,
                  transition: 'transform 0.5s ease-out',
                  textAlign: 'center',
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => setIsRevealed(true)}
                  sx={{
                    px: 4,
                    py: 2,
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    borderRadius: '50px',
                    background: 'linear-gradient(45deg, #8B0000 0%, #DC143C 100%)',
                    border: '2px solid rgba(218, 165, 32, 0.5)',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.3)',
                    color: '#fff',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #DC143C 0%, #8B0000 100%)',
                      transform: 'translateY(-2px)',
                      boxShadow: '0 6px 25px rgba(0,0,0,0.35)',
                      border: '2px solid rgba(218, 165, 32, 0.8)',
                    },
                    transition: 'all 0.3s ease',
                  }}
                >
                  Reveal Hall of Fame 🏆
                </Button>
              </Box>

              {/* Content */}
              <Card
                elevation={3}
                sx={{
                  height: '100%',
                  borderRadius: 2,
                  background: (theme) => `${theme.palette.background.paper}`,
                  opacity: isRevealed ? 1 : 0.3,
                  transition: 'opacity 0.5s ease-out 0.7s',
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      color: 'primary.main',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontSize: '2rem',
                      textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
                      mb: 3,
                      opacity: isRevealed ? 1 : 0,
                      transform: isRevealed ? 'translateY(0)' : 'translateY(30px)',
                      transition: 'opacity 0.8s ease-out 1s, transform 0.8s ease-out 1s',
                    }}
                  >
                    The Fantasy Taco Hall of Fame 🏆
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{
                      textAlign: 'center',
                      color: 'primary.main',
                      mb: 4,
                      opacity: isRevealed ? 1 : 0,
                      transform: isRevealed ? 'translateY(0)' : 'translateY(30px)',
                      transition: 'opacity 0.8s ease-out 1.2s, transform 0.8s ease-out 1.2s',
                    }}
                  >
                    Celebrating the greatest minds in dynasty fantasy football!
                  </Typography>
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      opacity: isRevealed ? 1 : 0,
                      transform: isRevealed ? 'translateY(0)' : 'translateY(30px)',
                      transition: 'opacity 0.8s ease-out 1.4s, transform 0.8s ease-out 1.4s',
                    }}
                  >
                    {[
                      {
                        name: 'Sigmund Bloom',
                        avatarUrl:
                          'https://pbs.twimg.com/profile_images/95569061/seamonster_400x400.jpg',
                        link: 'https://x.com/SigmundBloom',
                        linkDisplay: '@SigmundBloom',
                        tribute:
                          'Sigmund Bloom, your insight and wisdom have truly redefined fantasy football for us at Fantasy Taco. It was through your podcast, The Audible, that we first fell in love with the game. Your unique perspective and thoughtful analysis not only make every discussion engaging, but also inspire us to think differently about life and love. Thank you for being a constant source of wisdom and clarity – helping so many of us find a place we feel we belong.',
                      },
                      {
                        name: "Who's Next?",
                        avatarUrl: '',
                        link: '',
                        linkDisplay: 'Coming Soon',
                        isPlaceholder: true,
                      },
                    ].map((analyst) => (
                      <Grid item xs={12} sm={6} key={analyst.name}>
                        <Card
                          sx={{
                            minHeight: 350,
                            p: 3,
                            cursor: 'pointer',
                            background: (theme) =>
                              analyst.isPlaceholder
                                ? `linear-gradient(145deg, ${theme.palette.grey[200]} 0%, ${theme.palette.background.paper} 100%)`
                                : `linear-gradient(145deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
                            border: (theme) => `2px solid ${theme.palette.primary.main}`,
                          }}
                          onClick={() =>
                            setFlippedCard(flippedCard === analyst.name ? null : analyst.name)
                          }
                        >
                          {flippedCard === analyst.name ? (
                            // Back of card
                            <Box sx={{ textAlign: 'center' }}>
                              <Typography
                                variant="h6"
                                sx={{ color: 'primary.main', mb: 3, fontWeight: 'bold' }}
                              >
                                {analyst.isPlaceholder
                                  ? 'Nominate'
                                  : `Why ${analyst.name} is great`}
                              </Typography>
                              {analyst.isPlaceholder ? (
                                <Box
                                  component="form"
                                  sx={{
                                    mt: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 2,
                                    p: 2,
                                    borderRadius: 2,
                                    bgcolor: 'rgba(255, 255, 255, 0.7)',
                                    backdropFilter: 'blur(4px)',
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                  onSubmit={handleSubmitNomination}
                                >
                                  <Box onClick={(e) => e.stopPropagation()} sx={{ width: '100%' }}>
                                    <TextField
                                      fullWidth
                                      label="Enter Twitter handle"
                                      variant="outlined"
                                      value={nomination}
                                      onChange={(e) => setNomination(e.target.value)}
                                      placeholder="@username"
                                      onClick={(e) => e.stopPropagation()}
                                      onFocus={(e) => e.stopPropagation()}
                                      InputProps={{
                                        sx: {
                                          bgcolor: 'background.paper',
                                          '&:hover': {
                                            bgcolor: 'background.default',
                                          },
                                          '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'primary.main',
                                          },
                                          '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'primary.dark',
                                          },
                                        },
                                      }}
                                      InputLabelProps={{
                                        sx: { color: 'primary.main' },
                                      }}
                                    />
                                  </Box>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                      bgcolor: 'primary.main',
                                      '&:hover': { bgcolor: 'primary.dark' },
                                      py: 1.5,
                                      fontWeight: 'bold',
                                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                    }}
                                  >
                                    Submit Nomination
                                  </Button>
                                </Box>
                              ) : (
                                <Typography
                                  variant="body1"
                                  sx={{ color: 'primary.main', mb: 2, lineHeight: 1.6 }}
                                >
                                  {analyst.tribute}
                                </Typography>
                              )}
                              <Typography
                                variant="body2"
                                sx={{
                                  color: 'primary.main',
                                  mt: 3,
                                  opacity: 0.8,
                                  fontStyle: 'italic',
                                }}
                              >
                                (Click to flip back)
                              </Typography>
                            </Box>
                          ) : (
                            // Front of card
                            <Box sx={{ textAlign: 'center' }}>
                              <Avatar
                                src={analyst.avatarUrl}
                                sx={{
                                  width: 140,
                                  height: 140,
                                  margin: 'auto',
                                  mb: 2,
                                  border: '3px solid #ffd700',
                                  boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
                                  bgcolor: analyst.isPlaceholder ? '#e0e0e0' : undefined,
                                  fontSize: analyst.isPlaceholder ? '4rem' : undefined,
                                }}
                              >
                                {analyst.isPlaceholder ? '?' : analyst.name.charAt(0)}
                              </Avatar>
                              <Typography
                                variant="h6"
                                sx={{ color: 'primary.main', mb: 1, fontWeight: 'bold' }}
                              >
                                {analyst.name}
                              </Typography>
                              {!analyst.isPlaceholder && (
                                <Link
                                  href={analyst.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={(e) => e.stopPropagation()}
                                  sx={{
                                    color: 'primary.main',
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:hover': {
                                      textDecoration: 'underline',
                                      color: 'primary.main',
                                    },
                                  }}
                                >
                                  <TwitterIcon sx={{ mr: 1, fontSize: '1.2rem' }} />
                                  {analyst.linkDisplay}
                                </Link>
                              )}
                              {analyst.isPlaceholder && (
                                <>
                                  <Typography
                                    variant="body1"
                                    sx={{ color: 'primary.main', mt: 1, opacity: 0.8 }}
                                  >
                                    Nominate your favorite fantasy football mind!
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: 'primary.main',
                                      mt: 3,
                                      opacity: 0.8,
                                      fontStyle: 'italic',
                                    }}
                                  >
                                    (Click to vote)
                                  </Typography>
                                </>
                              )}

                              {!analyst.isPlaceholder && (
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: 'primary.main',
                                    mt: 3,
                                    opacity: 0.8,
                                    fontStyle: 'italic',
                                  }}
                                >
                                  (Click to see more)
                                </Typography>
                              )}
                            </Box>
                          )}
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>

          {/* Newsletter Signup */}
          <Grid item xs={12}>
            <NewsletterSignup
              title="Join the Fantasy Taco Community! 📬"
              description="Get the latest dynasty insights, prospect analysis, and feature updates delivered straight to your inbox."
              buttonText="Join Now"
            />
          </Grid>

          {/* Contact */}
          <Grid item xs={12}>
            <Card
              elevation={3}
              sx={{ height: '100%', borderRadius: 2, bgcolor: 'primary.main', color: 'white' }}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                  Let's Connect! 🤝
                </Typography>
                <Typography variant="body1" paragraph>
                  Have questions or ideas for the site?
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                  }}
                >
                  <Link
                    href="https://x.com/fantasytaco420"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white',
                      textDecoration: 'none',
                      '&:hover': { opacity: 0.8 },
                    }}
                  >
                    <TwitterIcon sx={{ mr: 1 }} />
                    <Typography variant="body1">Follow me on X</Typography>
                  </Link>
                  <Typography sx={{ color: 'white', opacity: 0.7 }}>|</Typography>
                  <Link
                    href="mailto:party@fantasytaco.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white',
                      textDecoration: 'none',
                      '&:hover': { opacity: 0.8 },
                    }}
                  >
                    <EmailIcon sx={{ mr: 1 }} />
                    <Typography variant="body1">party@fantasytaco.com</Typography>
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Snackbar
          open={successOpen}
          autoHideDuration={6000}
          onClose={() => setSuccessOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setSuccessOpen(false)} severity="success" sx={{ width: '100%' }}>
            Thank you for your vote!
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default AboutPage;
