import React, { useEffect, useRef } from 'react';
import { Sigma } from 'sigma';
import Graph from 'graphology';
import ForceSupervisor from 'graphology-layout-force/worker';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const GraphContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '320px',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 2,
  background: '#f8fafc',
  '& canvas': {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
}));

const NetworkGraph = ({ data, simulationConfig }) => {
  const containerRef = useRef(null);
  const rendererRef = useRef(null);
  const layoutRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current || !data?.nodes) return;

    // Clean up any existing instances
    layoutRef.current?.kill();
    rendererRef.current?.kill();

    const graph = new Graph();

    // Add nodes
    data.nodes.forEach((node) => {
      graph.addNode(node.id, {
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: node.isCurrentUser ? 8 : 5,
        label: node.name,
        color: node.isCurrentUser ? '#FCD34D' : '#6EE7B7',
      });
    });

    // Add edges
    data.links.forEach((link) => {
      try {
        graph.addEdge(link.source, link.target, {
          size: Math.sqrt(link.value || 1),
          color: 'rgba(0, 0, 0, 0.1)',
        });
      } catch (e) {
        // Ignore edge errors
      }
    });

    // Initialize renderer
    rendererRef.current = new Sigma(graph, containerRef.current, {
      minCameraRatio: 0.1,
      maxCameraRatio: 20,
      labelSize: 8,
      labelColor: { color: '#1a1a1a' },
      labelBackgroundColor: { color: 'rgba(255, 255, 255, 0.9)', padding: 1 },
      renderLabels: true,
      labelDensity: 0.3,
      labelGridCellSize: 60,
      defaultNodeType: 'circle',
      defaultEdgeType: 'line',
      hideEdgesOnMove: true,
      hideLabelsOnMove: true,
      renderEdgeLabels: false,
      allowInvalidContainer: true,
      reducedMotion: true,
    });

    // Add drag behavior
    let draggedNode = null;
    let isDragging = false;

    rendererRef.current.on('downNode', (e) => {
      isDragging = true;
      draggedNode = e.node;
      graph.setNodeAttribute(draggedNode, 'highlighted', true);
    });

    // Handle both mouseup and upNode events
    const handleUp = () => {
      if (draggedNode) {
        graph.removeNodeAttribute(draggedNode, 'highlighted');
      }
      isDragging = false;
      draggedNode = null;
    };

    rendererRef.current.on('upNode', handleUp);
    rendererRef.current.on('mouseup', handleUp);
    rendererRef.current.getMouseCaptor().on('mouseup', handleUp);

    rendererRef.current.getMouseCaptor().on('mousemovebody', (e) => {
      if (!isDragging || !draggedNode) return;

      // Get new position of node
      const pos = rendererRef.current.viewportToGraph(e);

      graph.setNodeAttribute(draggedNode, 'x', pos.x);
      graph.setNodeAttribute(draggedNode, 'y', pos.y);

      // Prevent sigma from moving camera
      e.preventSigmaDefault();
      e.original.preventDefault();
      e.original.stopPropagation();
    });

    // Initialize layout
    layoutRef.current = new ForceSupervisor(graph, {
      settings: {
        attraction: 0.0001,
        repulsion: 0.1,
        gravity: 0.0001,
        inertia: 0.6,
        maxMove: 1000,
      },
      weighted: false,
    });

    // Start and stop layout after initial positioning
    layoutRef.current.start();
    setTimeout(() => {
      layoutRef.current?.stop();
    }, 2000);

    return () => {
      layoutRef.current?.kill();
      rendererRef.current?.kill();
    };
  }, [data]);

  return <GraphContainer ref={containerRef} />;
};

export default NetworkGraph;
