import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Typography, Card, CardContent, Grid, Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Title } from 'react-admin';
import NewsletterSignup from './NewsletterSignup';

const TacoBackground = () => {
  const createGridTacos = () => {
    const tacos = [];
    const rows = 6;
    const cols = 8;
    const baseOpacity = 0.3;

    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        const baseTop = (row / (rows - 1)) * 85 + 5;
        const baseLeft = (col / (cols - 1)) * 85 + 5;
        const randomOffsetTop = (Math.random() - 0.5) * 8;
        const randomOffsetLeft = (Math.random() - 0.5) * 8;

        tacos.push({
          id: row * cols + col,
          top: `${baseTop + randomOffsetTop}%`,
          left: `${baseLeft + randomOffsetLeft}%`,
          animationDelay: `${Math.random() * 2}s`,
          opacity: baseOpacity + Math.random() * 0.15,
          floatDuration: 2 + Math.random() * 1,
        });
      }
    }
    return tacos;
  };

  const tacos = createGridTacos();

  return (
    <>
      {tacos.map((taco) => (
        <Box
          key={taco.id}
          sx={{
            position: 'absolute',
            top: taco.top,
            left: taco.left,
            fontSize: '22px',
            opacity: taco.opacity,
            animation: `floatEffect ${taco.floatDuration}s ease-in-out infinite, driftEffect ${
              5 + Math.random() * 3
            }s linear infinite`,
            animationDelay: taco.animationDelay,
            zIndex: 0,
            filter: 'blur(0.5px)',
            '&::before': {
              content: '"🌮"',
            },
            '&:hover': {
              opacity: 0.5,
              transition: 'opacity 0.5s ease-out',
            },
          }}
        />
      ))}
    </>
  );
};

const StartPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleNavigate = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  return (
    <Box>
      <Helmet prioritizeSeoTags={true} defer={false}>
        <title>Fantasy Taco | Dynasty Fantasy Football Tools</title>
        <meta
          name="description"
          content="Your premier destination for dynasty fantasy football analysis, player rankings, mock drafts, and league management tools. Search manager histories, analyze prospects, and dominate your league."
        />

        {/* Open Graph */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Fantasy Taco | Dynasty Fantasy Football Tools" />
        <meta
          property="og:description"
          content="Your premier destination for dynasty fantasy football analysis, player rankings, mock drafts, and league management tools. Search manager histories, analyze prospects, and dominate your league."
        />
        <meta property="og:image" content="https://fantasytaco.com/light_taco.png" />
        <meta property="og:image:alt" content="Fantasy Taco Logo" />
        <meta property="og:url" content="https://fantasytaco.com" />
        <meta property="og:site_name" content="Fantasy Taco" />

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@fantasytaco420" />
        <meta name="twitter:title" content="Fantasy Taco | Dynasty Fantasy Football Tools" />
        <meta
          name="twitter:description"
          content="Your premier destination for dynasty fantasy football analysis, player rankings, mock drafts, and league management tools. Search manager histories, analyze prospects, and dominate your league."
        />
        <meta name="twitter:image" content="https://fantasytaco.com/light_taco.png" />
        <meta name="twitter:image:alt" content="Fantasy Taco Logo" />
      </Helmet>
      <Title title="Fantasy Taco" />
      <Box
        sx={{
          height: '40vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'linear-gradient(135deg, #1e3c2f 0%, #2d4b3e 50%, #1e3c2f 100%)', // Rich forest green gradient
          position: 'relative',
          marginTop: 2,
          overflow: 'hidden',
          boxShadow: 'inset 0 0 100px rgba(0,0,0,0.2)', // Inner shadow for depth
          borderRadius: (theme) => theme.shape.borderRadius + 'px',
          '@keyframes floatEffect': {
            '0%, 100%': {
              transform: 'translateY(-8px)',
            },
            '50%': {
              transform: 'translateY(8px)',
            },
          },
          '@keyframes driftEffect': {
            '0%': {
              transform: 'translate(0, 0)',
            },
            '33%': {
              transform: 'translate(25px, -15px)',
            },
            '66%': {
              transform: 'translate(-15px, 25px)',
            },
            '100%': {
              transform: 'translate(0, 0)',
            },
          },
        }}
      >
        <TacoBackground />

        <Typography
          variant="h2"
          gutterBottom
          align="center"
          sx={{
            color: '#ffffff',
            textShadow: (theme) =>
              `4px 4px 0 ${theme.palette.primary.light}, 8px 8px 0 ${theme.palette.secondary.light}`,
            fontWeight: 900,
            fontFamily: "'Russo One', sans-serif",
            fontSize: { xs: '3.5rem', sm: '4.5rem', md: '5.5rem' },
            letterSpacing: '0.05em',
            marginBottom: 0,
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              filter: 'blur(20px)',
              opacity: 0.3,
              zIndex: -1,
            },
          }}
        >
          FANTASY TACO
        </Typography>

        <Typography
          variant="h6"
          align="center"
          sx={{
            color: 'rgba(255,255,255,0.9)',
            zIndex: 1,
            textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
            maxWidth: '800px',
            px: 3,
            mt: 2,
          }}
        >
          Welcome to Fantasy Taco! We build tools for dynasty GMs 🫵
        </Typography>
      </Box>

      <Box sx={{ maxWidth: 1200, margin: 'auto', px: 3, pb: 4, mt: 4 }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Card
              elevation={4}
              sx={{
                height: '100%',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.02)',
                  cursor: 'pointer',
                },
              }}
              onClick={() => handleNavigate('/usernames')}
            >
              <CardContent sx={{ p: 4, textAlign: 'center' }}>
                <Typography variant="h1" sx={{ mb: 2 }}>
                  🔍
                </Typography>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
                  Search General Managers
                </Typography>
                <Typography variant="body1" paragraph sx={{ mb: 3 }}>
                  Search Sleeper usernames to see who orphans teams, who trades a lot, and who is
                  committed to dynasty.
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => handleNavigate('/usernames')}
                  sx={{ minWidth: 200 }}
                >
                  Start Searching
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card
              elevation={4}
              sx={{
                height: '100%',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.02)',
                  cursor: 'pointer',
                },
              }}
              onClick={() => handleNavigate('/players')}
            >
              <CardContent sx={{ p: 4, textAlign: 'center' }}>
                <Typography variant="h1" sx={{ mb: 2 }}>
                  📚
                </Typography>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
                  2025 Prospects
                </Typography>
                <Typography variant="body1" paragraph sx={{ mb: 3 }}>
                  We compile content on the top 2025 NFL prospects to help you get ahead of your
                  league.
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => handleNavigate('/players')}
                  sx={{ minWidth: 200 }}
                >
                  View Prospects
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card
              elevation={4}
              sx={{
                height: '100%',
                transition: 'all 0.3s ease',
                position: 'relative',
                background: (theme) =>
                  `linear-gradient(145deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[100]} 100%)`,
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: (theme) => theme.shadows[8],
                },
              }}
            >
              <CardContent sx={{ p: 4, textAlign: 'center' }}>
                <Typography variant="h1" sx={{ mb: 2 }}>
                  🔮
                </Typography>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
                  Mock Draft Simulator
                </Typography>
                <Typography variant="body1" paragraph sx={{ mb: 2 }}>
                  Practice drafting with your actual league settings! Import your Sleeper league and
                  run unlimited mock drafts with your actual picks.
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 2,
                    color: 'text.secondary',
                    fontStyle: 'italic',
                    bgcolor: 'action.hover',
                    py: 1,
                    px: 2,
                    borderRadius: 1,
                  }}
                >
                  Coming soon!
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => handleNavigate('/mock-drafts')}
                  sx={{ minWidth: 200 }}
                >
                  Learn More
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Box sx={{ mt: 6, mb: 4 }}>
          <NewsletterSignup
            title="Stay in the Loop"
            description="So far we've built two tools. Enter your email to get notified when we release new tools."
            buttonText="Join"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default StartPage;
