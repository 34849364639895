import React, { useState, useEffect } from 'react';
import { Show, useRecordContext, useShowController, useGetOne } from 'react-admin';
import {
  Typography,
  Paper,
  Box,
  Card,
  CardContent,
  Grid,
  Avatar,
  CardHeader,
  List,
  ListItem,
  Button,
  Tooltip,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { PageBreadcrumbs } from '../utils/StyledBreadcrumbs';
import { styled } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { alpha } from '@mui/material/styles';
import NetworkGraph from './NetworkGraph';
import AdPlaceholder from '../utils/AdPlaceholder';
import { mapArchetypeToIcon } from '../utils/archetypeUtils';
import { Link } from 'react-router-dom';
import LoadingScreen from '../utils/LoadingScreen';
import InfoIcon from '@mui/icons-material/Info';
import { Helmet } from 'react-helmet-async';

const UserMetaTags = () => {
  const record = useRecordContext();
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaImage, setMetaImage] = useState('');
  const [metaUrl, setMetaUrl] = useState('');

  useEffect(() => {
    if (!record) {
      return;
    }

    const encodedName = encodeURIComponent(record.displayName);
    const currentUrl = `https://fantasytaco.com/usernames/${encodedName}/show`;

    const imageUrl = record.avatarUrl
      ? `https://sleepercdn.com/avatars/${record.avatarUrl}`
      : 'https://fantasytaco.com/light_taco.png';

    const description = `${record.displayName}'s dynasty fantasy football profile on Fantasy Taco. View league history, trade history, and manager analysis.`;

    setMetaTitle(`${record.displayName} | Fantasy Taco`);
    setMetaDescription(description);
    setMetaImage(imageUrl);
    setMetaUrl(currentUrl);
  }, [record]);

  if (!record || !metaTitle || !metaUrl) {
    return null;
  }

  return (
    <Helmet prioritizeSeoTags={true} defer={false}>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />

      {/* Open Graph */}
      <meta property="og:type" content="profile" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:image:alt" content={`${record.displayName}'s profile picture`} />
      <meta property="og:url" content={metaUrl} />
      <meta property="og:site_name" content="Fantasy Taco" />

      {/* Twitter Cards */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@fantasytaco420" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />
      <meta name="twitter:image:alt" content={`${record.displayName}'s profile picture`} />
    </Helmet>
  );
};

const CoverImage = styled(Box)(({ theme }) => ({
  height: 320,
  background: '#f8fafc',
  position: 'relative',
  marginBottom: 84,
  borderBottom: '1px solid #E0E0E0',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    background:
      'linear-gradient(to bottom, rgba(248, 250, 252, 0.1) 0%, rgba(248, 250, 252, 0.2) 100%)',
    zIndex: 1,
    pointerEvents: 'none',
  },
}));

const ProfileWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  margin: '0 auto',
  padding: 0,
  position: 'relative',
  background: '#fff',
  borderBottom: '1px solid #E0E0E0',
  overflow: 'hidden',
  boxSizing: 'border-box',
  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
  },
}));

// Create a new container for the avatar
const AvatarContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  height: 84, // Half the avatar height
  marginBottom: 84, // Half the avatar height
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 168,
  height: 168,
  border: '4px solid #fff',
  position: 'absolute',
  bottom: -84, // Half the height to create overlap
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: '#fff',
  borderRadius: '50%',
  overflow: 'hidden',
  zIndex: 2,
}));

// Update the ProfileHeader to remove the top margin
const ProfileHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  textAlign: 'left',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const ProfileTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"DM Sans", sans-serif',
  fontSize: '2rem',
  fontWeight: 700,
  color: '#1a1a1a',
  marginBottom: theme.spacing(0.5),
}));

const ProfileSubtitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"DM Sans", sans-serif',
  fontSize: '1rem',
  color: '#65676B',
  marginBottom: theme.spacing(1),
  '&::after': {
    display: 'none',
  },
}));

const StatBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  background: '#fff',
  borderRadius: '4px',
  border: '1px solid #E0E0E0',
  boxShadow: 'none',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: '#E0E0E0',
  },
  '& .MuiSvgIcon-root': {
    color: '#666',
    fontSize: '1.8rem',
  },
  '& .MuiTypography-h4': {
    fontFamily: '"DM Serif Display", serif',
    color: '#333',
    fontSize: '2.2rem',
  },
  '& .MuiTypography-body2': {
    fontFamily: '"DM Sans", sans-serif',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    fontSize: '0.85rem',
    fontWeight: 500,
  },
}));

// Update LeagueCard styling to use consistent colors
const LeagueCard = styled(Box)(({ theme }) => ({
  width: '100%',
  background: '#fff',
  borderRadius: '12px',
  position: 'relative',
  transition: 'all 0.2s ease',
  overflow: 'hidden',
  boxShadow: '0 2px 20px rgba(0, 0, 0, 0.05)',
  border: '1px solid #E0E0E0',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
    borderRadius: '8px',
  },
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 25px rgba(0, 0, 0, 0.1)',
  },
}));

// Update LeagueCardType styling to use consistent colors
const LeagueCardType = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0.5, 2),
  borderRadius: '50px',
  fontSize: '0.7rem',
  fontWeight: 600,
  textTransform: 'uppercase',
  letterSpacing: '0.1em',
  color: '#4B5563', // Consistent gray color
  background: '#F3F4F6', // Light gray background
  border: '1px solid #E5E7EB',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
  minWidth: '80px',
  height: '24px',
  marginTop: theme.spacing(0.5),
}));

// Update LeagueHeaderInfo to accommodate absolute positioning
const LeagueHeaderInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  width: '100%',
  position: 'relative',
}));

// Update LeagueMainInfo to include margin for the pill
const LeagueMainInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  width: '100%',
  marginBottom: theme.spacing(1),
}));

// Update LeagueLogo to position the image and year tag better
const LeagueLogo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  position: 'relative',
  '& img': {
    width: '64px',
    height: '64px',
    borderRadius: '8px',
    objectFit: 'cover',
    border: '1px solid #E0E0E0',
    padding: '4px',
    background: '#fff',
    flexShrink: 0,
  },
}));

const LeagueCardName = styled(Typography)(({ theme }) => ({
  fontFamily: '"DM Sans", sans-serif',
  fontSize: '1.25rem',
  fontWeight: 700,
  color: '#1a1a1a',
  lineHeight: 1.2,
}));

// Update StatGrid styling - remove the background since the whole card is now colored
const StatGrid = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  borderRadius: '8px',
  background: '#fff',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const StatRow = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr', // Changed from 1fr 1fr 1fr to 1fr 1fr since there are only 2 stats
  gap: theme.spacing(2),
  justifyContent: 'center',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
  },
}));

const StatItem = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  '& .stat-value': {
    fontSize: '1.1rem',
    fontWeight: 700,
    background: 'linear-gradient(135deg, #1a1a1a 0%, #4a4a4a 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    marginBottom: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4rem',
    },
  },
  '& .stat-label': {
    fontSize: '0.7rem',
    color: '#666',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    [theme.breakpoints.up('md')]: {
      fontSize: '0.75rem',
    },
  },
}));

const LeagueSettings = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: '#666',
  textAlign: 'center',
  fontWeight: 500,
  wordBreak: 'break-word',
  whiteSpace: 'normal',
  lineHeight: 1.2,
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.7rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.8rem',
  },
}));

const YearSelector = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(1),
  borderBottom: '1px solid #E0E0E0',
  overflowX: 'auto',
  WebkitOverflowScrolling: 'touch',
  msOverflowStyle: '-ms-autohiding-scrollbar',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
}));

const YearButton = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  cursor: 'pointer',
  fontFamily: '"DM Sans", sans-serif',
  color: '#666',
  position: 'relative',
  transition: 'all 0.2s ease',
  whiteSpace: 'nowrap',
  fontSize: { xs: '0.9rem', sm: '1rem' },
  '&.selectedYear': {
    color: '#333',
    fontWeight: 600,
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: -8,
      left: 0,
      right: 0,
      height: 2,
      background: '#333',
    },
  },
  '&:hover': {
    color: '#333',
  },
}));

const fetchLeaguesByYear = (record, year) => {
  const allLeagues = record.leagues.sort((a, b) => b.year - a.year);

  if (year === 'all') {
    return allLeagues;
  }
  return allLeagues.filter((league) => league.year === year);
};

const fetchSnapshotByYear = (record, year) => {
  return record.snapshots.find((s) => s.year === year) || {};
};

const generateArchetype = (selectedSnapshot, record) => {
  if (!selectedSnapshot?.archetype && !record?.snapshots?.[0]?.archetype) {
    return {
      name: 'Loading...',
      icon: '🌮',
    };
  }

  return {
    name: selectedSnapshot?.archetype || record?.snapshots?.[0]?.archetype,
    icon: mapArchetypeToIcon(selectedSnapshot?.archetype || record?.snapshots?.[0]?.archetype),
  };
};

// Add new LimitedDataBanner component
const LimitedDataBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1, 2),
  borderRadius: '8px',
  background: 'rgba(249, 115, 22, 0.05)',
  border: '1px solid rgba(249, 115, 22, 0.1)',
  marginTop: theme.spacing(1),
  marginRight: theme.spacing(0),
  '& .icon': {
    color: '#f97316',
    fontSize: '1.1rem',
    flexShrink: 0,
  },
  '& .text': {
    color: '#9a3412',
    fontSize: '0.8rem',
    fontWeight: 500,
    fontFamily: '"DM Sans", sans-serif',
  },
}));

const NetworkGraphWrapper = ({ userId, record }) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const queryId = React.useMemo(() => `${record.displayName}/network`, [record.displayName]);

  const {
    data: networkData,
    isLoading,
    error,
  } = useGetOne(
    'usernames',
    { id: queryId },
    {
      enabled: !!record.displayName,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    }
  );

  // Add error logging
  React.useEffect(() => {
    if (error) {
      console.error('Network data fetch error:', error);
    }
  }, [error]);

  const graphData = React.useMemo(() => {
    if (networkData?.data) {
      return networkData.data;
    } else if (networkData) {
      return networkData;
    }

    return {
      nodes: [
        {
          id: 'current',
          name: record.displayName,
          isCurrentUser: true,
          avatar: record.avatarUrl
            ? `https://sleepercdn.com/avatars/${record.avatarUrl}`
            : '/light_taco.png',
        },
      ],
      links: [],
    };
  }, [networkData, record]);

  // Initial load
  useEffect(() => {
    if (graphData && graphData.nodes.length > 0) {
      const timer = setTimeout(() => {
        setIsInitialLoading(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [graphData]);

  // Handle very large datasets warning
  const isLargeDataset = graphData.nodes.length > 600;
  const isLimitedData = networkData?.limited || false;

  if (isInitialLoading) {
    return (
      <LoadingScreen
        message="Building your taco network..."
        submessage={
          isLargeDataset
            ? 'You have a large network! Optimizing for better performance...'
            : '(Connecting to the fantasy football taco-verse)'
        }
      />
    );
  }

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <NetworkGraph
        data={graphData}
        simulationConfig={{
          settings: {
            attraction: 0.0001,
            repulsion: 0.1,
            gravity: 0.0001,
            inertia: 0.6,
            maxMove: 1000,
          },
        }}
      />
      {isLimitedData && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 16,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 10,
            background: 'rgba(0, 0, 0, 0.7)',
            borderRadius: '20px',
            padding: '8px 16px',
          }}
        >
          <Typography
            sx={{
              color: '#fff',
              fontSize: '0.8rem',
              fontWeight: 500,
              textAlign: 'center',
            }}
          >
            Network visualization limited to 1,000 connections
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const ArchetypeBadge = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(0.5, 2),
  background: '#fff',
  border: '1px solid #E0E0E0',
  borderRadius: '20px',
  marginTop: theme.spacing(1),
  cursor: 'pointer',
  textDecoration: 'none',
  transition: 'all 0.2s ease',
  '&:hover': {
    background: '#f8fafc',
    borderColor: '#2563eb',
    transform: 'translateY(-1px)',
  },
  '& .archetype-icon': {
    fontSize: '1.2rem',
  },
  '& .archetype-text': {
    fontFamily: '"DM Sans", sans-serif',
    fontSize: '0.9rem',
    color: '#4B5563',
    fontWeight: 500,
  },
}));

const getAvatarUrl = (avatarUrl) => {
  if (!avatarUrl) return '/light_taco.png';
  return `https://sleepercdn.com/avatars/${avatarUrl}`;
};

const fetchTradesByLeagues = (record, leagues) => {
  const trades = [];
  for (const trade of record.trades) {
    if (leagues.some((league) => league.id === trade.leagueId)) {
      trades.push(trade);
    }
  }
  // Sort trades by createdAt in descending order
  return trades.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
};

const TradeCard = ({ trade }) => (
  <Card
    sx={{
      mb: { xs: 3, sm: 3 },
      borderRadius: 2,
      border: '1px solid #E0E0E0',
      boxShadow: 'none',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
      },
    }}
  >
    <CardHeader
      avatar={
        <Avatar
          src={getAvatarUrl(trade.leagueAvatarUrl)}
          alt={trade.leagueName}
          sx={{
            width: { xs: 32, sm: 48 },
            height: { xs: 32, sm: 48 },
            border: '2px solid #fff',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          }}
        />
      }
      title={
        <Box>
          <Typography
            variant="h6"
            sx={{
              display: { xs: 'none', sm: 'block' },
              fontSize: { xs: '0.95rem', sm: '1rem' },
              fontWeight: 600,
              lineHeight: 1.4,
              fontFamily: '"DM Sans", sans-serif',
              color: '#1a1a1a',
              mb: 0.5,
            }}
          >
            {trade.leagueName}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontSize: { xs: '0.7rem', sm: '0.8rem' },
              color: '#666',
              textTransform: 'uppercase',
              letterSpacing: '0.5px',
            }}
          >
            {trade.leagueType} • {trade.createdAt}
          </Typography>
        </Box>
      }
      sx={{
        borderBottom: '1px solid #f0f0f0',
        background: '#fafafa',
        p: { xs: 1.5, sm: 2.5 },
        '& .MuiCardHeader-content': {
          overflow: 'hidden',
        },
      }}
    />
    <CardContent sx={{ p: { xs: 1.5, sm: 2.5 } }}>
      {(trade.transaction.length === 2 ? trade.transaction.slice(0, 1) : trade.transaction).map(
        (part, idx) => (
          <Box key={idx} sx={{ mb: 2 }}>
            <Grid container spacing={{ xs: 1, sm: 3 }} alignItems="flex-start">
              <Grid item xs={5}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    mb: 1,
                    color: '#2563eb',
                    fontSize: { xs: '0.8rem', sm: '0.95rem' },
                  }}
                >
                  {part.username.displayName} received
                </Typography>
                {(part.playersAcquired.length > 0 || part.picksAcquired.length > 0) && (
                  <List dense sx={{ p: 0 }}>
                    {part.playersAcquired.map((player, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          pl: 0,
                          py: { xs: 0.5, sm: 1 },
                          fontSize: { xs: '0.75rem', sm: '0.9rem' },
                          color: '#374151',
                        }}
                      >
                        <span style={{ fontWeight: 500 }}>{player.name}</span>
                        <Box
                          component="span"
                          sx={{
                            color: '#6B7280',
                            ml: 1,
                            display: { xs: 'none', sm: 'inline' },
                          }}
                        >
                          ({player.position} – {player.nfl_team})
                        </Box>
                        <Box
                          component="span"
                          sx={{
                            color: '#6B7280',
                            ml: 1,
                            display: { xs: 'inline', sm: 'none' },
                          }}
                        >
                          ({player.position})
                        </Box>
                      </ListItem>
                    ))}
                    {part.picksAcquired.map((pick, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          pl: 0,
                          py: { xs: 0.5, sm: 1 },
                          fontSize: { xs: '0.75rem', sm: '0.9rem' },
                          color: '#374151',
                        }}
                      >
                        {pick.season} Round {pick.round}
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                justifyContent="center"
                sx={{ px: { xs: 0.5, sm: 1.5 } }}
              >
                <ArrowForwardIcon
                  sx={{
                    color: '#9CA3AF',
                    transform: { xs: 'scale(0.7)', sm: 'scale(1.2)' },
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                {trade.transaction.length === 2 && idx === 0 && (
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600,
                      mb: 1,
                      color: '#DC2626',
                      fontSize: { xs: '0.8rem', sm: '0.95rem' },
                    }}
                  >
                    {trade.transaction[1].username.displayName} received
                  </Typography>
                )}
                {(part.playersDropped.length > 0 || part.picksDropped.length > 0) && (
                  <List dense sx={{ p: 0 }}>
                    {part.playersDropped.map((player, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          pl: 0,
                          py: { xs: 0.5, sm: 1 },
                          fontSize: { xs: '0.75rem', sm: '0.9rem' },
                          color: '#374151',
                        }}
                      >
                        <span style={{ fontWeight: 500 }}>{player.name}</span>
                        <Box
                          component="span"
                          sx={{
                            color: '#6B7280',
                            ml: 1,
                            display: { xs: 'none', sm: 'inline' },
                          }}
                        >
                          ({player.position} – {player.nfl_team})
                        </Box>
                        <Box
                          component="span"
                          sx={{
                            color: '#6B7280',
                            ml: 1,
                            display: { xs: 'inline', sm: 'none' },
                          }}
                        >
                          ({player.position})
                        </Box>
                      </ListItem>
                    ))}
                    {part.picksDropped.map((pick, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          pl: 0,
                          py: { xs: 0.5, sm: 1 },
                          fontSize: { xs: '0.75rem', sm: '0.9rem' },
                          color: '#374151',
                        }}
                      >
                        {pick.season} Round {pick.round}
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>
            </Grid>
          </Box>
        )
      )}
    </CardContent>
  </Card>
);

// Update the TradesSection component to include ads after every 4th trade with mobile optimization
const TradesSection = ({ trades }) => {
  const [expanded, setExpanded] = useState(false);
  const initialTradesCount = 10;

  // Create a new array with ads inserted after every 4th trade
  const getTradesWithAds = (tradesList) => {
    const result = [];

    tradesList.forEach((trade, index) => {
      // Add the trade
      result.push(<TradeCard key={`trade-${index}`} trade={trade} />);

      // Add an ad after every 4th trade (but not after the last one if it happens to be the 4th)
      if ((index + 1) % 4 === 0 && index < tradesList.length - 1) {
        result.push(
          <Box
            key={`ad-after-trade-${index}`}
            sx={{
              my: { xs: 2, md: 3 },
              width: '100%',
              maxWidth: '100%',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              // border: '1px dashed #ccc', // Add a border to make it visible during development
              padding: 2,
              backgroundColor: '#f9f9f9', // Light background to make it stand out
            }}
          >
            <AdPlaceholder type="horizontal" />
          </Box>
        );
      }
    });

    return result;
  };

  const displayedTrades = expanded ? trades : trades.slice(0, initialTradesCount);
  const hasMoreTrades = trades.length > initialTradesCount;

  // Make sure we're actually using the function to insert ads
  const tradesWithAds = getTradesWithAds(displayedTrades);

  // Add console log to debug
  console.log(`Displaying ${displayedTrades.length} trades with ads inserted every 4th trade`);

  return (
    <Box sx={{ p: { xs: 1, sm: 2 }, mb: 3 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontSize: { xs: '1.25rem', sm: '1.5rem' },
            fontWeight: 700,
            color: '#1a1a1a',
            fontFamily: '"DM Sans", sans-serif',
          }}
        >
          Recent Trades
        </Typography>
        {hasMoreTrades && (
          <Button
            onClick={() => setExpanded(!expanded)}
            variant="outlined"
            size="small"
            sx={{
              textTransform: 'none',
              borderColor: '#E5E7EB',
              color: '#374151',
              fontSize: { xs: '0.8rem', sm: '0.875rem' },
              '&:hover': {
                borderColor: '#D1D5DB',
                backgroundColor: '#F9FAFB',
              },
            }}
          >
            {expanded ? 'Show Less' : `Show All (${trades.length})`}
          </Button>
        )}
      </Box>

      {/* Render trades with ads inserted */}
      {tradesWithAds.length > 0 ? (
        tradesWithAds
      ) : (
        <Typography sx={{ textAlign: 'center', py: 4, color: '#6B7280' }}>
          No trades to display
        </Typography>
      )}

      {!expanded && hasMoreTrades && (
        <Box
          sx={{
            textAlign: 'center',
            mt: 2,
          }}
        >
          <Button
            onClick={() => setExpanded(true)}
            variant="text"
            sx={{
              color: '#6B7280',
              fontSize: { xs: '0.8rem', sm: '0.875rem' },
              '&:hover': {
                backgroundColor: '#F9FAFB',
              },
            }}
          >
            Show {trades.length - initialTradesCount} More Trades
          </Button>
        </Box>
      )}
    </Box>
  );
};

const UserProfile = () => {
  const [selectedYear, setSelectedYear] = useState('all');
  const record = useRecordContext();

  if (!record?.snapshots?.length) {
    return (
      <Box sx={{ p: 4, textAlign: 'center' }}>
        <Typography variant="h6">No data available for this GM</Typography>
      </Box>
    );
  }

  // Use the mock data functions instead of record data
  const filteredLeagues = fetchLeaguesByYear(record, selectedYear);
  const selectedSnapshot = fetchSnapshotByYear(record, selectedYear);
  const trades = fetchTradesByLeagues(record, filteredLeagues);

  // Get unique years from the mock data
  const years = ['all', ...new Set(fetchLeaguesByYear(record, 'all').map((l) => l.year))].sort(
    (a, b) => {
      if (a === 'all') return -1;
      if (b === 'all') return 1;
      return b - a;
    }
  );

  // Helper function to insert ads after every 3rd row (instead of every 2nd)
  const getLeaguesWithAds = (leagues) => {
    if (!leagues || leagues.length === 0) return [];

    const result = [];

    // Process each league and create proper Grid items
    leagues.forEach((league, index) => {
      // Add the league as a Grid item
      result.push(
        <Grid item xs={12} sm={6} md={4} key={league.id || `league-${index}`}>
          {league.isAd ? (
            // If it's an ad placeholder, render the ad component
            league.render()
          ) : (
            // If it's a league, render the LeagueCard
            <LeagueCard>
              <LeagueHeaderInfo>
                <LeagueMainInfo>
                  <LeagueLogo>
                    <img
                      src={getAvatarUrl(league.avatarUrl)}
                      alt={league.name}
                      onError={(e) => {
                        e.target.src = '/light_taco.png';
                      }}
                    />
                    <YearTag yearColor={getYearColor(league.year)}>{league.year}</YearTag>
                  </LeagueLogo>
                  <LeagueInfoBox>
                    <LeagueCardName variant="h6">{league.name}</LeagueCardName>
                    <LeagueCardType>{league.type}</LeagueCardType>
                  </LeagueInfoBox>
                </LeagueMainInfo>
              </LeagueHeaderInfo>

              <StatGrid>
                <StatRow>
                  <StatItem>
                    <div className="stat-value">
                      {league.wins === 0 && league.losses === 0 && league.ties === 0 ? (
                        <span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>Not Started</span>
                      ) : (
                        <>
                          {league.wins}-{league.losses}
                          {league.ties > 0 ? `-${league.ties}` : ''}
                        </>
                      )}
                    </div>
                    <div className="stat-label">Record</div>
                  </StatItem>
                  <StatItem>
                    <div className="stat-value">
                      {league.wins === 0 && league.losses === 0 && league.ties === 0 ? (
                        <span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>-</span>
                      ) : (
                        <>
                          {(
                            (league.wins / (league.wins + league.losses + league.ties)) *
                            100
                          ).toFixed(1)}
                          %
                        </>
                      )}
                    </div>
                    <div className="stat-label">Win %</div>
                  </StatItem>
                </StatRow>
                <LeagueSettings>
                  {league.numTeams} Teams • {league.superflex ? 'SF' : '1QB'} •{league.numStarters}{' '}
                  Starters • {league.ppr ? 'PPR' : 'Non-PPR'} •
                  {league.bestBall ? 'Best Ball' : 'Standard'}
                </LeagueSettings>
              </StatGrid>
            </LeagueCard>
          )}
        </Grid>
      );

      // Insert ads after every 3 leagues (instead of 2)
      if ((index + 1) % 3 === 0 && index < leagues.length - 1) {
        result.push(
          <Grid item xs={12} key={`ad-after-${index}`}>
            <Box
              sx={{
                my: 2,
                width: '100%',
                maxWidth: '100%',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <AdPlaceholder type="horizontal" />
            </Box>
          </Grid>
        );
      }
    });

    return result;
  };

  return (
    <Box>
      <UserMetaTags />
      <CoverImage>
        <NetworkGraphWrapper userId={record.id} record={record} />
      </CoverImage>

      <ProfileWrapper>
        <ContentLayout>
          <Box>
            <LeftColumn sx={{ position: 'relative' }}>
              <AvatarContainer>
                <ProfileAvatar
                  src={
                    record.avatarUrl
                      ? `https://sleepercdn.com/avatars/${record.avatarUrl}`
                      : '/light_taco.png'
                  }
                  alt={record.displayName}
                />
              </AvatarContainer>
              <ProfileHeader>
                <ProfileTitle variant="h4">{record.displayName}</ProfileTitle>
                <ProfileSubtitle variant="subtitle1">@{record.sleeperUsername}</ProfileSubtitle>
                <Tooltip title="Click to learn more about archetypes" arrow placement="top">
                  <Link
                    to="/blogs/Understanding%20Dynasty%20Archetypes/show"
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ArchetypeBadge>
                      <span className="archetype-icon">
                        {generateArchetype(selectedSnapshot, record).icon}
                      </span>
                      <span className="archetype-text">
                        {generateArchetype(selectedSnapshot, record).name}
                      </span>
                    </ArchetypeBadge>
                  </Link>
                </Tooltip>
                {record.limited && (
                  <LimitedDataBanner>
                    <InfoIcon className="icon" />
                    <Typography className="text">
                      Showing last 100 leagues for website performance reasons 😊
                    </Typography>
                  </LimitedDataBanner>
                )}
              </ProfileHeader>
              <ProfileInfo>
                <div className="info-item">
                  <CalendarMonthIcon />
                  Joined {record?.snapshots?.[record?.snapshots?.length - 1]?.year}
                </div>
              </ProfileInfo>

              {/* Replace the sidebar ad with one that's hidden on mobile */}
              <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                <AdPlaceholder location="sidebar" />
              </Box>
            </LeftColumn>

            {/* Hide these ads on mobile devices */}
            <Box sx={{ mt: 3, display: { xs: 'none', md: 'block' } }}>
              <AdPlaceholder type="square" />
            </Box>
            <Box sx={{ mt: 3, display: { xs: 'none', md: 'block' } }}>
              <AdPlaceholder type="vertical" />
            </Box>
          </Box>

          <RightColumn>
            <YearSelector>
              {years.map((year) => (
                <YearButton
                  key={year}
                  className={selectedYear === year ? 'selectedYear' : ''}
                  onClick={() => setSelectedYear(year)}
                >
                  {year === 'all' ? 'All Time' : year}
                </YearButton>
              ))}
            </YearSelector>

            {/* Stats Summary */}
            <Box sx={{ mb: 4 }}>
              <Grid container spacing={3}>
                {/* Key Stats Section */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 3,
                      background:
                        'linear-gradient(to right, rgba(37, 99, 235, 0.03), rgba(37, 99, 235, 0.07))',
                      borderRadius: '12px',
                      border: '1px solid rgba(37, 99, 235, 0.1)',
                      mb: 3,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 2,
                        fontSize: '0.9rem',
                        fontWeight: 600,
                        color: '#2563eb',
                        textTransform: 'uppercase',
                        letterSpacing: '0.05em',
                        fontFamily: '"DM Sans", sans-serif',
                      }}
                    >
                      Key Stats
                    </Typography>
                    <Grid container spacing={2}>
                      {/* Original top row items */}
                      <Grid item xs={12} sm={4}>
                        <Tooltip title="Click to learn more about archetypes" arrow placement="top">
                          <Link
                            to="/blogs/Understanding%20Dynasty%20Archetypes/show"
                            style={{
                              textDecoration: 'none',
                              display: 'block',
                              height: '100%',
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <StatBox
                              elevation={0}
                              sx={{
                                cursor: 'pointer',
                                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                                '&:hover': {
                                  transform: 'translateY(-2px)',
                                  boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 1,
                                  justifyContent: 'center',
                                  height: '100%',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography variant="h4" sx={{ fontSize: '2.5rem', mb: 0.5 }}>
                                  {generateArchetype(selectedSnapshot, record).icon}
                                </Typography>
                                <Box sx={{ textAlign: 'center' }}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: '0.85rem',
                                      textTransform: 'uppercase',
                                      letterSpacing: '0.05em',
                                      mb: 0.5,
                                    }}
                                  >
                                    ARCHETYPE
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontWeight: 500,
                                      color: '#1a1a1a',
                                      fontFamily: '"DM Sans", sans-serif',
                                    }}
                                  >
                                    {generateArchetype(selectedSnapshot, record).name}
                                  </Typography>
                                </Box>
                              </Box>
                            </StatBox>
                          </Link>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <StatBox elevation={0}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '100%',
                            }}
                          >
                            <Typography
                              variant="h4"
                              sx={{
                                fontSize: { xs: '1.5rem', sm: '2rem' },
                                whiteSpace: 'nowrap',
                                lineHeight: 1.2,
                                mb: 1,
                              }}
                            >
                              {formatNumber(selectedSnapshot.yearlyWins || 0)}W -{' '}
                              {formatNumber(selectedSnapshot.yearlyLosses || 0)}L
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {selectedYear === 'all' ? 'All Time Record' : 'Season Record'}
                            </Typography>
                          </Box>
                        </StatBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <StatBox elevation={0}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '100%',
                            }}
                          >
                            <Typography variant="h4">
                              {(
                                (selectedSnapshot.yearlyWins /
                                  (selectedSnapshot.yearlyWins + selectedSnapshot.yearlyLosses)) *
                                  100 || 0
                              ).toFixed(1)}
                              %
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {selectedYear === 'all' ? 'Career Win Rate' : 'Season Win Rate'}
                            </Typography>
                          </Box>
                        </StatBox>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                {/* League Performance Section */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 3,
                      background:
                        'linear-gradient(to right, rgba(16, 185, 129, 0.02), rgba(16, 185, 129, 0.05))',
                      borderRadius: '16px',
                      border: '1px solid rgba(16, 185, 129, 0.1)',
                      mb: 3,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 2,
                        fontSize: '0.9rem',
                        fontWeight: 600,
                        color: '#10b981',
                        textTransform: 'uppercase',
                        letterSpacing: '0.05em',
                        fontFamily: '"DM Sans", sans-serif',
                      }}
                    >
                      Management Performance
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <StatGroupBox title="Managed Teams">
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              py: 1,
                            }}
                          >
                            <Typography
                              variant="h4"
                              sx={{
                                fontSize: { xs: '2rem', sm: '2.5rem' },
                                fontWeight: 800,
                                color: '#1a1a1a',
                                mb: 2,
                              }}
                            >
                              {formatNumber(selectedSnapshot.activeDynastyLeagues || 0)}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: '#6B7280',
                                fontSize: '0.75rem',
                                fontWeight: 500,
                                letterSpacing: '0.05em',
                                textTransform: 'uppercase',
                              }}
                            >
                              Teams
                            </Typography>
                          </Box>
                        </StatGroupBox>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <StatGroupBox title="Championships">
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              py: 1,
                            }}
                          >
                            <Typography
                              variant="h4"
                              sx={{
                                fontSize: { xs: '2rem', sm: '2.5rem' },
                                fontWeight: 800,
                                color: '#1a1a1a',
                                mb: 2,
                              }}
                            >
                              {formatNumber(selectedSnapshot.dynastyChampionshipsWon || 0)}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: '#6B7280',
                                fontSize: '0.75rem',
                                fontWeight: 500,
                                letterSpacing: '0.05em',
                                textTransform: 'uppercase',
                              }}
                            >
                              Championships
                            </Typography>
                          </Box>
                        </StatGroupBox>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <StatGroupBox title="Orphaned Teams">
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              py: 1,
                            }}
                          >
                            <Typography
                              variant="h4"
                              sx={{
                                fontSize: { xs: '2rem', sm: '2.5rem' },
                                fontWeight: 800,
                                color: '#1a1a1a',
                                mb: 2,
                              }}
                            >
                              {formatNumber(selectedSnapshot.orphanedDynastyLeagues || 0)}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: '#6B7280',
                                fontSize: '0.75rem',
                                fontWeight: 500,
                                letterSpacing: '0.05em',
                                textTransform: 'uppercase',
                              }}
                            >
                              Leagues
                            </Typography>
                          </Box>
                        </StatGroupBox>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                {/* League Management Section */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 3,
                      background:
                        'linear-gradient(to right, rgba(249, 115, 22, 0.03), rgba(249, 115, 22, 0.07))',
                      borderRadius: '12px',
                      border: '1px solid rgba(249, 115, 22, 0.1)',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 2,
                        fontSize: '0.9rem',
                        fontWeight: 600,
                        color: '#f97316',
                        textTransform: 'uppercase',
                        letterSpacing: '0.05em',
                        fontFamily: '"DM Sans", sans-serif',
                      }}
                    >
                      Player Acquisitions
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <StatGroupBox title="Trades">
                          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                py: 1,
                              }}
                            >
                              <Typography variant="h4" sx={{ mb: 2 }}>
                                {formatNumber(selectedSnapshot.totalDynastyTrades || 0)}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: '#6B7280',
                                  fontSize: '0.75rem',
                                  fontWeight: 500,
                                  letterSpacing: '0.05em',
                                  textTransform: 'uppercase',
                                }}
                              >
                                Trades Executed
                              </Typography>
                            </Box>
                          </Box>
                        </StatGroupBox>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <StatGroupBox title="Draft Picks">
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100%',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                py: 1,
                              }}
                            >
                              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 2 }}>
                                <Box sx={{ textAlign: 'center' }}>
                                  <Typography variant="h4" sx={{ mb: 0.5 }}>
                                    {formatNumber(selectedSnapshot.picksTradedFor || 0)}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: '#6B7280',
                                      fontSize: '0.75rem',
                                      fontWeight: 500,
                                      letterSpacing: '0.05em',
                                      textTransform: 'uppercase',
                                    }}
                                  >
                                    Acquired
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    borderLeft: '2px solid #E0E0E0',
                                    alignSelf: 'stretch',
                                    margin: '0 4px',
                                  }}
                                />
                                <Box sx={{ textAlign: 'center' }}>
                                  <Typography variant="h4" sx={{ mb: 0.5 }}>
                                    {formatNumber(selectedSnapshot.picksTradedAway || 0)}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: '#6B7280',
                                      fontSize: '0.75rem',
                                      fontWeight: 500,
                                      letterSpacing: '0.05em',
                                      textTransform: 'uppercase',
                                    }}
                                  >
                                    Sent
                                  </Typography>
                                </Box>
                              </Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: '#6B7280',
                                  fontSize: '0.75rem',
                                  fontWeight: 500,
                                  letterSpacing: '0.05em',
                                  textTransform: 'uppercase',
                                }}
                              >
                                Draft Picks Traded
                              </Typography>
                            </Box>
                          </Box>
                        </StatGroupBox>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <StatGroupBox title="Transactions">
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              py: 1,
                            }}
                          >
                            <Typography variant="h4" sx={{ mb: 2 }}>
                              {formatNumber(selectedSnapshot.totalDynastyTransactions || 0)}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: '#6B7280',
                                fontSize: '0.75rem',
                                fontWeight: 500,
                                letterSpacing: '0.05em',
                                textTransform: 'uppercase',
                              }}
                            >
                              Total Transactions
                            </Typography>
                          </Box>
                        </StatGroupBox>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* Replace the horizontal ad above leagues with a smaller ad */}
            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center' }}>
              <AdPlaceholder type="horizontal" />
            </Box>

            {/* Trades */}
            <TradesSection trades={trades} />

            {/* League Cards */}
            <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
              {getLeaguesWithAds(filteredLeagues)}
            </Grid>
          </RightColumn>
        </ContentLayout>

        {/* Replace the horizontal ad at the bottom with a smaller ad */}
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <AdPlaceholder type="horizontal" />
        </Box>
      </ProfileWrapper>
    </Box>
  );
};

const UsernameTitle = () => {
  const record = useRecordContext();
  return <span>{record ? record.displayName : ''}</span>;
};

export const UsernameShow = () => {
  // Custom show component that uses display name for URL but UUID internally
  const ShowWrapper = ({ children }) => {
    const { record, isLoading, error } = useShowController();
    if (error) return null;

    if (!record) {
      return (
        <Box>
          <ProfileWrapper sx={{ height: '100vh' }}>
            <ContentLayout>
              <LoadingScreen message="Loading GM profile..." />
            </ContentLayout>
          </ProfileWrapper>
        </Box>
      );
    }

    // Override the record's id with the encoded display name for URL purposes
    const modifiedRecord = {
      ...record,
      id: encodeURIComponent(record.id),
    };

    return (
      <>
        <PageBreadcrumbs />
        <Show id={modifiedRecord.id} title={<UsernameTitle />}>
          {children}
        </Show>
      </>
    );
  };

  return (
    <ShowWrapper>
      <UserProfile />
    </ShowWrapper>
  );
};

// Update ContentLayout to use width constraints
const ContentLayout = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing(2),
  padding: { xs: theme.spacing(1), sm: theme.spacing(2) },
  width: '100%',
  maxWidth: '100%',
  margin: '0 auto',
  overflow: 'hidden',
  boxSizing: 'border-box',
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '300px 1fr',
    padding: theme.spacing(3),
    maxWidth: '100%',
    gap: theme.spacing(3),
  },
}));

// Update LeftColumn to look better on mobile
const LeftColumn = styled(Box)(({ theme }) => ({
  background: '#fff',
  border: '1px solid #E0E0E0',
  borderRadius: '8px',
  height: 'fit-content',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    marginBottom: 0,
  },
}));

// Update RightColumn to handle both mobile and desktop properly
const RightColumn = styled(Box)(({ theme }) => ({
  background: '#fff',
  border: { xs: 'none', sm: '1px solid #E0E0E0' },
  borderRadius: { xs: 0, sm: '8px' },
  padding: { xs: theme.spacing(1), sm: theme.spacing(2) },
  width: '100%',
  maxWidth: '100%',
  boxSizing: 'border-box',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(3),
    border: '1px solid #E0E0E0',
    borderRadius: '8px',
  },
}));

// Add this new styled component
const ProfileInfo = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid #E0E0E0',
  '& .info-item': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: '#65676B',
    fontSize: '0.9rem',
    fontFamily: '"DM Sans", sans-serif',
    marginBottom: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
      color: '#65676B',
    },
  },
}));

// Update LeagueInfoBox to ensure vertical centering
const LeagueInfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '64px',
}));

// Redesign the YearTag to be more compact and positioned at the top of the logo
const YearTag = styled(Box)(({ theme, yearColor }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0.25, 1),
  borderRadius: '8px',
  fontSize: '0.7rem',
  fontWeight: 600,
  backgroundColor: alpha(yearColor, 0.1),
  color: yearColor,
  border: `1px solid ${alpha(yearColor, 0.2)}`,
  position: 'absolute',
  top: -8,
  left: '50%',
  transform: 'translateX(-50%)',
  minWidth: '40px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
  background: '#fff',
}));

// Function to generate consistent colors for years
const getYearColor = (year) => {
  const colors = {
    2024: '#2563eb', // Blue
    2023: '#16a34a', // Green
    2022: '#9333ea', // Purple
    2021: '#ea580c', // Orange
    2020: '#dc2626', // Red
    2019: '#0891b2', // Cyan
    2018: '#db2777', // Pink
    2017: '#9333ea', // Purple
  };
  return colors[year] || '#6b7280'; // Default gray for other years
};

const StatGroupBox = styled(({ title, children, ...props }) => (
  <Paper {...props}>
    <Typography
      variant="h6"
      sx={{
        p: { xs: 1.5, sm: 2 },
        borderBottom: '1px solid #E0E0E0',
        fontSize: '0.85rem',
        fontWeight: 600,
        color: '#4B5563',
        textTransform: 'uppercase',
        letterSpacing: '0.05em',
        fontFamily: '"DM Sans", sans-serif',
        background: '#FAFAFA',
      }}
    >
      {title}
    </Typography>
    <Box
      sx={{
        p: { xs: 2, sm: 3 },
        height: 'calc(100% - 57px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </Box>
  </Paper>
))(({ theme }) => ({
  height: '100%',
  background: '#fff',
  border: '1px solid #E0E0E0',
  borderRadius: '12px',
  boxShadow: 'none',
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  },
  '& .MuiTypography-h4': {
    fontSize: { xs: '2rem', sm: '2.5rem' },
    fontWeight: 800,
    color: '#1a1a1a',
    textAlign: 'center',
    marginBottom: theme.spacing(0.5),
    lineHeight: 1,
  },
  '& .MuiTypography-body2': {
    fontSize: '0.75rem',
    color: '#6B7280',
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    fontWeight: 500,
  },
}));

// Add this helper function for number formatting
const formatNumber = (num) => {
  if (num === null || num === undefined) return '0';
  return num.toLocaleString();
};
