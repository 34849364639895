import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  email,
  useRedirect,
  useNotify,
  SaveButton,
  Toolbar,
} from 'react-admin';
import { Box, Typography, Paper, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import AdPlaceholder from '../utils/AdPlaceholder';
import { PageBreadcrumbs } from '../utils/StyledBreadcrumbs';
const StyledPaper = styled(Paper)(({ theme }) => ({
  maxWidth: 800,
  margin: '0 auto',
  padding: theme.spacing(4),
  background: '#fff',
  border: '1px solid #E0E0E0',
  borderRadius: '8px',
  boxShadow: 'none',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: '#E0E0E0',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: '"DM Sans", sans-serif',
  fontSize: '2rem',
  fontWeight: 700,
  color: '#1a1a1a',
  marginBottom: theme.spacing(3),
}));

const Description = styled(Typography)(({ theme }) => ({
  fontFamily: '"DM Sans", sans-serif',
  fontSize: '1rem',
  color: '#65676B',
  marginBottom: theme.spacing(4),
  lineHeight: 1.5,
}));

const ProcessingNotice = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderRadius: '8px',
  '& .MuiAlert-message': {
    fontFamily: '"DM Sans", sans-serif',
  },
}));

const StyledForm = styled(SimpleForm)(({ theme }) => ({
  '& .MuiTextField-root': {
    marginBottom: theme.spacing(3),
  },
  '& .MuiInputLabel-root': {
    fontFamily: '"DM Sans", sans-serif',
    color: '#1a1a1a',
    fontWeight: 500,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    '& fieldset': {
      borderColor: '#E0E0E0',
    },
    '&:hover fieldset': {
      borderColor: '#B0B0B0',
    },
  },
}));

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Add" />
  </Toolbar>
);

export const UsernameCreate = () => {
  const notify = useNotify();

  const onSuccess = (response) => {
    const message = response.message || 'Success! It will be ready soon!';
    notify(message, { type: 'success' });

    // Save userId if it's returned and doesn't exist in localStorage
    const createdUserId = response?.userId || response?.username?.userId;
    if (createdUserId && !localStorage.getItem('userId')) {
      localStorage.setItem('userId', createdUserId);
    }
  };

  const onError = (error) => {
    const message = error.body.error || 'An error occurred';
    notify(message, { type: 'error' });
  };

  const transform = (data) => {
    return {
      ...data,
      userId: localStorage.getItem('userId'),
    };
  };

  return (
    <>
      <PageBreadcrumbs />
      <Create redirect="list" mutationOptions={{ onSuccess, onError }} transform={transform}>
        <Box sx={{ display: 'flex', gap: 3, justifyContent: 'center' }}>
          {/* Main content column */}
          <Box sx={{ maxWidth: 800 }}>
            {/* Top ad - horizontal banner */}
            <Box sx={{ mb: 3 }}>
              <AdPlaceholder type="horizontal" />
            </Box>

            <StyledPaper>
              <Title>Add New General Manager</Title>
              <Description>
                Can't find the Sleeper username you're looking for? We'll add it for you.
              </Description>

              <ProcessingNotice severity="info">
                Processing time varies from a few minutes to a few hours, depending on traffic and
                how hardcore the GM is. We'll get it done as quickly as possible. Drop your email
                below and we'll notify you when it's ready.
              </ProcessingNotice>

              <StyledForm wrapperProps={{ sx: { padding: 0 } }} toolbar={<CustomToolbar />}>
                <TextInput
                  source="display_name"
                  label="Sleeper Username"
                  validate={[required()]}
                  fullWidth
                />
                <TextInput
                  source="email"
                  label="Your Email"
                  validate={[email()]}
                  helperText="Optional - we'll notify you when the GM is ready"
                  fullWidth
                />
              </StyledForm>
            </StyledPaper>

            {/* Bottom ad - default (medium rectangle) */}
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
              <AdPlaceholder type="square" />
            </Box>
          </Box>

          {/* Right side vertical ad - updated with sticky positioning and centering */}
          <Box
            sx={{
              position: 'sticky',
              top: 24,
              alignSelf: 'flex-start',
              height: 'calc(100vh - 48px)',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <AdPlaceholder type="vertical" />
          </Box>
        </Box>
      </Create>
    </>
  );
};
