import React from 'react';
import { fetchUtils, Admin, Resource, CustomRoutes } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { BrowserRouter } from 'react-router-dom';
import { Route as RouterRoute } from 'react-router';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { createBrowserHistory } from 'history';
import { UsernameShow } from './components/UsernameShow';
import { UsernameList } from './components/UsernameList';
import { UsernameCreate } from './components/UsernameCreate';
import { PlayersList } from './components/PlayersList';
import { PlayerShow } from './components/PlayerShow';
import { BlogList } from './components/BlogList';
import { BlogShow } from './components/BlogShow';
import AboutPage from './components/AboutPage';
import StartPage from './components/StartPage';
import CustomLayout from './layout/CustomLayout';
import theme from './theme';
import MockDraftList from './components/MockDraftList';

const history = createBrowserHistory();

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('auth');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const API_URL = process.env.REACT_APP_ENV === 'local' ? 'http://localhost:3000/api' : '/api';

const dataProvider = simpleRestProvider(API_URL, httpClient);

const App = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Admin
          layout={CustomLayout}
          dataProvider={dataProvider}
          menuIcon="🌮"
          theme={theme}
          basename=""
          history={history}
        >
          <Resource
            name="blogs"
            list={BlogList}
            show={BlogShow}
            options={{ label: 'Blog' }}
            recordRepresentation="title"
            id="slug"
          />
          <Resource
            name="usernames"
            list={UsernameList}
            show={UsernameShow}
            options={{ label: 'General Managers' }}
            recordRepresentation="display_name"
            id="display_name"
            create={UsernameCreate}
          />
          <Resource
            name="players"
            list={PlayersList}
            show={PlayerShow}
            options={{ label: '2025 NFL Prospects' }}
            recordRepresentation="name"
            id="name"
          />
          <Resource name="mock-drafts" list={MockDraftList} />
          <CustomRoutes>
            <RouterRoute path="/" element={<StartPage />} options={{ label: 'Home' }} />
            <RouterRoute path="/about" element={<AboutPage />} />
            <RouterRoute path="/start" element={<StartPage />} options={{ label: 'Home' }} />
          </CustomRoutes>
        </Admin>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
