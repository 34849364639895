import React, { useState, useEffect } from 'react';
import { List, SearchInput, useListContext, Pagination } from 'react-admin';
import StartSit from './StartSit';
import { Box, Typography, Grid, Card, CardContent, CardActionArea, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AdPlaceholder from '../utils/AdPlaceholder';
import { PageBreadcrumbs } from '../utils/StyledBreadcrumbs';
import LoadingScreen from '../utils/LoadingScreen';

const getFullPosition = (position) => {
  const positionMap = {
    QB: 'Quarterback',
    RB: 'Running Back',
    WR: 'Wide Receiver',
    TE: 'Tight End',
  };
  return positionMap[position] || position;
};

// Add position emoji mapping
const getPositionEmoji = (position) => {
  const emojiMap = {
    QB: '🎯',
    RB: '🏃',
    WR: '🙌',
    TE: '🦾',
  };
  return emojiMap[position] || '🏈';
};

const PlayerCard = ({ record }) => {
  const navigate = useNavigate();

  if (!record) return null;

  // Get position info
  const mainPosition = Array.isArray(record.fantasy_positions)
    ? record.fantasy_positions[0]
    : record.fantasy_positions;
  const positionEmoji = getPositionEmoji(mainPosition);
  const fullPositions = Array.isArray(record.fantasy_positions)
    ? record.fantasy_positions.map(getFullPosition).join(' / ')
    : getFullPosition(record.fantasy_positions);

  return (
    <Card
      sx={{
        height: '100%',
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 12px 24px -10px rgba(0, 0, 0, 0.1)',
        },
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        overflow: 'hidden',
        bgcolor: 'white',
      }}
    >
      <CardActionArea
        onClick={() => navigate(`/players/${encodeURIComponent(record.name)}/show`)}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          '&:hover img': {
            transform: 'scale(1.05)',
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            height: { xs: 160, sm: 180 },
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f8f9fa',
          }}
        >
          <img
            src={`/images/2025_rookie_images/${record.sleeper_id}.png`}
            alt={record.name}
            onError={(e) => {
              e.target.src = '/light_taco.png';
            }}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              transition: 'transform 0.3s ease',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              background:
                'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0) 100%)',
              pt: 4,
              pb: 1.5,
              px: 2,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontSize: { xs: '1rem', sm: '1.15rem' },
                fontWeight: 600,
                fontFamily: '"DM Sans", sans-serif',
                color: 'white',
                textShadow: '0 1px 2px rgba(0,0,0,0.3)',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {record.name}
            </Typography>
          </Box>
        </Box>

        <CardContent
          sx={{
            p: { xs: 1, sm: 1.5 },
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            bgcolor: 'white',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              p: { xs: '0.75rem 1.5rem', sm: '1rem 2rem' },
              borderRadius: '12px',
              bgcolor: '#f3f4f6',
              mx: { xs: -0.5, sm: -1 },
              transition: 'background-color 0.2s ease',
              '&:hover': {
                bgcolor: '#eef1f5',
              },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '1.3rem', sm: '1.4rem' },
                lineHeight: 1,
              }}
            >
              {positionEmoji}
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography
                sx={{
                  fontSize: { xs: '0.85rem', sm: '0.9rem' },
                  fontWeight: 600,
                  color: '#111827',
                  lineHeight: 1.2,
                }}
              >
                {fullPositions}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '0.75rem', sm: '0.8rem' },
                  color: '#6B7280',
                  mt: 0.3,
                }}
              >
                {record.college || 'College N/A'}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const PlayersListContent = () => {
  const { data, isLoading } = useListContext();

  if (isLoading) {
    return (
      <Box sx={{ height: '100vh' }}>
        <LoadingScreen
          message="Loading players..."
          submessage="Gathering the latest prospect data"
        />
      </Box>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Box textAlign="center" m={1}>
        <Typography
          variant="body1"
          sx={{
            color: 'text.secondary',
            fontSize: '1rem',
            mt: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 0.5,
            flexWrap: 'wrap',
          }}
        >
          Missing a prospect?
          <Link
            href="https://twitter.com/fantasytaco420"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: 'primary.main',
              textDecoration: 'none',
              fontWeight: 500,
              borderBottom: '2px solid transparent',
              transition: 'all 0.2s ease',
              '&:hover': {
                borderBottom: '2px solid',
                borderColor: 'primary.main',
              },
            }}
          >
            Contact us on Twitter
          </Link>
          and we'll add them to the database.
        </Typography>
      </Box>
    );
  }

  const renderContent = () => {
    const content = [];

    // Add players and intermittent ads
    data.forEach((record, index) => {
      // Add a horizontal ad after every 8 players (2 rows of 4)
      if (index > 0 && index % 8 === 0) {
        content.push(
          <Grid item xs={12} key={`ad-${index}`}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                my: 2,
                width: '100%',
                maxWidth: '100%',
                overflow: 'hidden',
              }}
            >
              <AdPlaceholder type="horizontal" />
            </Box>
          </Grid>
        );
      }

      content.push(
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          lg={3}
          key={record.id}
          sx={{ minWidth: { xs: '50%', sm: '300px' } }}
        >
          <Box sx={{ width: '100%', height: '100%' }}>
            <PlayerCard record={record} />
          </Box>
        </Grid>
      );
    });

    // Add final ad at the end
    content.push(
      <Grid
        item
        xs={6}
        sm={6}
        md={4}
        lg={3}
        key="final-ad"
        sx={{ minWidth: { xs: '50%', sm: '300px' } }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          <AdPlaceholder type="square" />
        </Box>
      </Grid>
    );

    return content;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} sx={{ width: '100%', margin: '0 auto' }}>
        {renderContent()}
      </Grid>
    </Box>
  );
};

const filters = [
  <SearchInput
    source="name"
    alwaysOn
    disableSaveQuery
    disableTranslate
    placeholder="Search 2025 NFL prospects..."
    sx={{
      '& .MuiInputBase-root': {
        width: '100%',
        minWidth: '320px',
        maxWidth: '420px',
        margin: '0 auto',
        background: 'transparent',
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
        },
        '&:hover fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.2) !important',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.3) !important',
        },
      },
      '& .MuiInputBase-input': {
        height: '40px',
        padding: '0 16px',
      },
      display: 'flex',
      justifyContent: 'center',
      mt: { xs: 3, sm: 4 },
      mb: 3,
    }}
  />,
];

const Empty = () => {
  const { filterValues } = useListContext();
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h6" paragraph>
        No players found
      </Typography>
      {filterValues?.name && (
        <Typography variant="body2" paragraph>
          No results found for "{filterValues.name}"
        </Typography>
      )}
      <Typography
        variant="body2"
        sx={{
          color: 'text.secondary',
          fontSize: '0.875rem',
          mt: 2,
        }}
      >
        Missing a prospect?{' '}
        <Link
          href="https://twitter.com/fantasytaco420"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          Contact us on Twitter
        </Link>{' '}
        and we'll add them to the database.
      </Typography>
    </Box>
  );
};

export const PlayersList = (props) => {
  // const [showStartSit, setShowStartSit] = useState(false);

  return (
    <>
      <PageBreadcrumbs />
      <Box
        sx={{
          px: { xs: 0, sm: 2, md: 3 },
          pt: { xs: 2, sm: 3 },
          pb: 4,
          maxWidth: '1600px',
          width: '100%',
          overflowX: 'hidden',
        }}
      >
        <List
          {...props}
          sort={{ field: 'views', order: 'DESC' }}
          exporter={false}
          filters={filters}
          empty={<Empty />}
          filterDefaultValues={{ name: '' }}
          perPage={100}
          hasCreate={false}
          pagination={false}
          component="div"
          actions={false}
          sx={{
            '& .RaList-main': {
              width: '100%',
              background: 'transparent',
              boxShadow: 'none',
              '& .MuiToolbar-root': {
                background: 'transparent',
                marginTop: { xs: 3, sm: 4 },
                minHeight: 0,
                padding: 0,
              },
              '& .MuiCardContent-root': {
                background: 'transparent',
                padding: 0,
              },
            },
            '& .RaList-content': {
              padding: 0,
              background: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <PlayersListContent />
        </List>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            my: { xs: 1, sm: 2 },
            width: '100%',
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          <AdPlaceholder type="horizontal" />
        </Box>
      </Box>
    </>
  );
};
