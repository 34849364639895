import { Layout, useResourceDefinitions, useGetResourceLabel, useSidebarState } from 'react-admin';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import CustomMenu from './CustomMenu';
import { StyledBreadcrumbs, BreadcrumbLink } from '../utils/StyledBreadcrumbs';

const MenuButton = styled(IconButton)(({ theme }) => ({
  padding: '8px',
  marginRight: '8px',
  fontSize: '24px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

const CustomAppBar = () => {
  const location = useLocation();
  const resources = useResourceDefinitions();
  const getResourceLabel = useGetResourceLabel();
  const [open, setOpen] = useSidebarState();

  const pathSegments = location.pathname.split('/').filter(Boolean);
  const breadcrumbs = ['Start Here'];

  // Generate breadcrumbs with their corresponding paths
  const breadcrumbsWithPaths = breadcrumbs.map((crumb, index) => ({
    label: crumb,
    path: '/start',
  }));

  if (pathSegments.length > 0) {
    const resource = pathSegments[0];

    if (resource === 'about') {
      breadcrumbsWithPaths.push({ label: 'About Fantasy Taco', path: '/about' });
    } else if (resource === 'blogs') {
      breadcrumbsWithPaths.push({ label: "Let's Taco Bout It", path: '/blogs' });
    } else if (resource === 'mock-drafts') {
      breadcrumbsWithPaths.push({ label: 'Mock Drafts', path: '/mock-drafts' });
    } else if (resource === 'start') {
      // Don't add anything for start page
    } else if (resources[resource]) {
      breadcrumbsWithPaths.push({
        label: getResourceLabel(resource, 2),
        path: `/${resource}`,
      });
    }

    // Add a third level for show/create/edit views
    if (pathSegments.length > 1 && pathSegments[1]) {
      breadcrumbsWithPaths.push({
        label: decodeURIComponent(pathSegments[1]),
        path: `/${resource}/${pathSegments[1]}`,
      });
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1200,
        padding: { xs: '12px 16px', sm: '8px' },
        display: { xs: 'flex', sm: 'none' },
        alignItems: 'center',
        backgroundColor: '#F5F7FA',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
        justifyContent: 'space-between',
        height: { xs: '56px', sm: 'auto' },
        boxSizing: 'border-box',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <MenuButton
          onClick={() => setOpen(!open)}
          size="small"
          aria-label={open ? 'Close menu' : 'Open menu'}
          sx={{
            padding: '8px',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '&:active': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          }}
        >
          🌮
        </MenuButton>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Russo One',
            fontSize: { xs: '1.1rem', sm: '1.2rem' },
            color: 'primary.main',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          Fantasy Taco
        </Typography>
      </Box>
    </Box>
  );
};

const CustomLayout = (props) => {
  return (
    <Layout
      {...props}
      appBar={CustomAppBar}
      menu={CustomMenu}
      sx={{
        '& .RaLayout-root': {
          paddingTop: { xs: '56px', sm: '8px' },
        },
        '& .RaLayout-appFrame': {
          marginTop: { xs: '0', sm: '24px' },
        },
        '& .RaLayout-content': {
          padding: { xs: '0', sm: '0' },
          backgroundColor: '#F5F7FA',
          marginTop: '0',
          width: '100%',
          maxWidth: '100%',
          overflowX: 'hidden',
        },
        '& .RaLayout-menu': {
          backgroundColor: (theme) => theme.palette.background.menu,
          borderRight: (theme) => `1px solid ${theme.palette.divider}`,
          top: { xs: '56px', sm: '0px !important' },
          position: 'fixed',
          height: { xs: 'calc(100vh - 56px)', sm: 'calc(100vh - 64px)' },
          width: { xs: '240px', sm: '240px' },
          transform: { xs: 'translateX(-240px)', sm: 'none' },
          transition: 'transform 0.3s ease-in-out',
          zIndex: 1199,
          '&.RaLayout-menuOpen': {
            transform: 'translateX(0)',
          },
          '& .RaMenu-item': {
            borderRadius: '8px',
            margin: '4px 8px',
            transition: 'background-color 0.2s ease',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '&:active': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
            '& .MuiListItemIcon-root': {
              minWidth: { xs: '40px', sm: '56px' },
            },
            '& .MuiListItemText-root': {
              margin: { xs: '4px 0', sm: '8px 0' },
            },
          },
        },
        // Add overlay when menu is open on mobile
        '& .RaLayout-menuClipped': {
          '&::before': {
            content: '""',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1198,
            opacity: 0,
            visibility: 'hidden',
            transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
          },
          '&.RaLayout-menuOpen::before': {
            opacity: 1,
            visibility: 'visible',
          },
        },
      }}
    />
  );
};

export default CustomLayout;
