import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const LoadingWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  background: 'rgba(248, 250, 252, 0.9)',
  zIndex: 2,
  '& .taco-emoji': {
    fontSize: '2.5rem',
    animation: 'bounce 1s ease-in-out infinite',
  },
  '& .loading-text': {
    fontFamily: '"DM Sans", sans-serif',
    color: '#1a1a1a',
    fontSize: '0.9rem',
    fontWeight: 500,
    letterSpacing: '0.5px',
    animation: 'pulse 1.5s ease-in-out infinite',
    textAlign: 'center',
  },
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'translateY(0) rotate(0deg)',
    },
    '50%': {
      transform: 'translateY(-20px) rotate(10deg)',
    },
  },
  '@keyframes pulse': {
    '0%, 100%': {
      opacity: 0.6,
    },
    '50%': {
      opacity: 1,
    },
  },
}));

const LoadingScreen = ({ message = 'Loading...', submessage = '' }) => {
  return (
    <LoadingWrapper>
      <div className="taco-emoji">🌮</div>
      <div className="loading-text">
        {message}
        {submessage && (
          <>
            <br />
            <span style={{ fontSize: '0.8rem', opacity: 0.8 }}>{submessage}</span>
          </>
        )}
      </div>
    </LoadingWrapper>
  );
};

export default LoadingScreen;
