import { Menu, useStore } from 'react-admin';
import { Typography, Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const MenuSection = ({ open, label, children }) => {
  if (!open) return <Box mt={1}>{children}</Box>;
  return (
    <Box mt={3}>
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{
          paddingLeft: 3,
          fontWeight: 'bold',
          textTransform: 'uppercase',
          fontSize: '0.8rem',
          opacity: open ? 0.7 : 0,
          transform: open ? 'translateX(0)' : 'translateX(-10px)',
          transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
        }}
      >
        {label}
      </Typography>
      <Box
        mt={1}
        sx={{
          '& .RaMenuItem-root': {
            '& .MuiListItemText-root': {
              transform: open ? 'translateX(0)' : 'translateX(-10px)',
              opacity: open ? 1 : 0,
              transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
            },
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const LogoTypography = styled(Typography)({
  fontFamily: 'Russo One',
});

const Logo = () => {
  const [open, setOpen] = useStore('sidebar.open', true);

  return (
    <Box
      display="flex"
      alignItems="left"
      justifyContent="center"
      pb={2}
      pt={{ xs: 4, sm: 2 }}
      px={{ xs: 2, sm: 0 }}
      onClick={() => setOpen(!open)}
      sx={{ cursor: 'pointer' }}
    >
      <span
        style={{
          fontSize: '30px',
          lineHeight: '1',
          textAlign: 'center',
          transform: open ? 'rotateY(0deg)' : 'rotateY(180deg)',
          transition: 'transform 0.3s ease-in-out',
          display: 'inline-block',
        }}
      >
        🌮
      </span>
      <LogoTypography
        variant="h6"
        align="left"
        px={1}
        sx={{
          color: 'primary.light',
          fontSize: '20px',
          textShadow: (theme) => `1.5px 1.5px 0 ${theme.palette.secondary.light}`,
          display: open ? 'block' : 'none',
        }}
      >
        FANTASY TACO
      </LogoTypography>
    </Box>
  );
};

const CustomMenu = (props) => {
  const [open] = useStore('sidebar.open', true);

  React.useEffect(() => {}, [open]);

  const handleMenuClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Box>
      <Logo />

      <Menu {...props} sx={{ borderTop: '1px solid #e0e0e0' }}>
        <MenuSection open={open} label="Getting Started">
          <Menu.Item
            to="/start"
            primaryText="Start Here"
            leftIcon={'🚀'}
            onClick={handleMenuClick}
          />
        </MenuSection>

        <MenuSection open={open} label="Draft Tools">
          <Menu.Item
            to="/players"
            primaryText="2025 Prospects"
            leftIcon={'👀'}
            onClick={handleMenuClick}
          />
          <Menu.Item
            to="/usernames"
            primaryText="GM Search"
            leftIcon={'🔍'}
            onClick={handleMenuClick}
          />
          <Menu.Item
            to="/mock-drafts"
            primaryText="Mock Drafts"
            leftIcon={'🔮'}
            onClick={handleMenuClick}
          />
        </MenuSection>

        <MenuSection open={open} label="Content">
          <Menu.Item
            to="/blogs"
            primaryText="Let's Taco Bout It"
            leftIcon={'📝'}
            onClick={handleMenuClick}
          />
          <Menu.Item
            to="/about"
            primaryText="About Fantasy Taco"
            leftIcon={'🌮'}
            onClick={handleMenuClick}
          />
        </MenuSection>
      </Menu>
    </Box>
  );
};

export default CustomMenu;
