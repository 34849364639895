import { Typography, Breadcrumbs, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, Link } from 'react-router-dom';
import { useResourceDefinitions, useGetResourceLabel } from 'react-admin';

export const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  padding: '8px 16px 0px 16px',
  background: 'transparent',
  display: 'flex',
  justifyContent: 'flex-start',
  '& .MuiBreadcrumbs-ol': {
    margin: 0,
  },
  '& .MuiBreadcrumbs-li': {
    fontSize: '14px',
  },
  '& .MuiBreadcrumbs-separator': {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    color: theme.palette.text.secondary,
  },
}));

export const BreadcrumbLink = styled(Typography)(({ theme, active }) => ({
  color: active ? theme.palette.primary.main : theme.palette.text.secondary,
  fontSize: '14px',
  fontWeight: active ? 600 : 400,
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export const PageBreadcrumbs = () => {
  const location = useLocation();
  const resources = useResourceDefinitions();
  const getResourceLabel = useGetResourceLabel();

  const pathSegments = location.pathname.split('/').filter(Boolean);
  const breadcrumbs = ['Start Here'];

  // Generate breadcrumbs with their corresponding paths
  const breadcrumbsWithPaths = breadcrumbs.map((crumb, index) => ({
    label: crumb,
    path: '/start',
  }));

  if (pathSegments.length > 0) {
    const resource = pathSegments[0];

    if (resource === 'about') {
      breadcrumbsWithPaths.push({ label: 'About Fantasy Taco', path: '/about' });
    } else if (resource === 'blogs') {
      breadcrumbsWithPaths.push({ label: "Let's Taco Bout It", path: '/blogs' });
    } else if (resource === 'mock-drafts') {
      breadcrumbsWithPaths.push({ label: 'Mock Drafts', path: '/mock-drafts' });
    } else if (resource === 'start') {
      // Don't add anything for start page
    } else if (resources[resource]) {
      breadcrumbsWithPaths.push({
        label: getResourceLabel(resource, 2),
        path: `/${resource}`,
      });
    }

    // Add a third level for show/create/edit views
    const defaultPages = ['show', 'create', 'edit'];
    if (pathSegments.length > 1 && pathSegments[1]) {
      breadcrumbsWithPaths.push({
        label: !defaultPages.includes(pathSegments[1])
          ? decodeURIComponent(pathSegments[1])
          : decodeURIComponent(pathSegments[1])
              .split('-')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' '),
        path: `/${resource}/${pathSegments[1]}`,
      });
    }
  }

  if (pathSegments[0] === 'start') {
    return null;
  }

  return (
    <StyledBreadcrumbs separator="›">
      {breadcrumbsWithPaths.map((crumb, index) => (
        <BreadcrumbLink
          key={crumb.label}
          active={index === breadcrumbsWithPaths.length - 1}
          component={index === breadcrumbsWithPaths.length - 1 ? 'span' : Link}
          to={index === breadcrumbsWithPaths.length - 1 ? undefined : crumb.path}
        >
          {crumb.label}
        </BreadcrumbLink>
      ))}
    </StyledBreadcrumbs>
  );
};
