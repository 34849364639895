export const mapArchetypeToIcon = (archetype) => {
  switch (archetype) {
    case 'League Abandoner':
      return '🏃'; // Running away
    case 'Vibesman':
      return '🌀'; // Vibes
    case 'Pick Hog':
      return '🐷'; // Pig/hog
    case 'Forever Rebuilder':
      return '👷‍♂️'; // Construction/building
    case 'Checkdown Charlie':
      return '🤓';
    case 'Waiver Wire Warrior':
      return '⚔️'; // Crossed swords
    case 'Auto-Drafter':
      return '🤖'; // Robot
    case 'Champ Chaser':
      return '👑'; // Crown
    case 'Portfolio Manager':
      return '💼'; // Briefcase
    case 'TBD':
      return '🤷‍♂️'; // shrugging shoulders
    case 'Coming Soon':
      return '⏳'; // hourglass
    default:
      return '🎮'; // Default gaming controller
  }
};
