import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const AdPlaceholder = ({ type = 'horizontal' }) => {
  const adRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [adLoaded, setAdLoaded] = useState(false);

  // Check if we're in production environment
  const isProduction = process.env.NODE_ENV === 'production';

  // Define ad sizes based on type using Google's top-performing ad sizes
  const getAdSize = () => {
    // On mobile, use smaller ad formats regardless of requested type
    if (isMobile) {
      switch (type) {
        case 'horizontal':
        case 'small_horizontal':
          return { maxWidth: '320px', maxHeight: '50px' }; // Mobile banner
        case 'vertical':
          return { maxWidth: '300px', maxHeight: '250px' }; // Medium rectangle for mobile
        case 'square':
        case 'small_square':
        default:
          return { maxWidth: '300px', maxHeight: '250px' }; // Medium rectangle
      }
    }

    // Desktop sizes
    switch (type) {
      case 'horizontal':
        return { maxWidth: '728px', maxHeight: '90px' }; // Leaderboard
      case 'small_horizontal':
        return { maxWidth: '300px', maxHeight: '50px' }; // Mobile banner
      case 'vertical':
        return { maxWidth: '160px', maxHeight: '600px' }; // Wide skyscraper
      case 'square':
        return { maxWidth: '336px', maxHeight: '280px' }; // Large rectangle
      case 'small_square':
        return { maxWidth: '300px', maxHeight: '250px' }; // Medium rectangle
      default:
        return { maxWidth: '728px', maxHeight: '90px' }; // Default to leaderboard
    }
  };

  const adSize = getAdSize();

  // Get the appropriate ad slot based on ad type
  const getAdSlot = () => {
    // Use mobile-optimized slots on mobile devices
    if (isMobile) {
      return '7243411254'; // Use horizontal ad slot for all mobile ads
    }

    switch (type) {
      case 'horizontal':
      case 'small_horizontal':
        return '7243411254'; // Horizontal ad slot
      case 'vertical':
        return '9051354937'; // Vertical ad slot
      case 'square':
      case 'small_square':
      default:
        return '9530701239'; // Square ad slot
    }
  };

  useEffect(() => {
    // Don't proceed if we're not in production or if certain ad types on mobile
    if (!isProduction || (isMobile && (type === 'vertical' || type === 'square'))) {
      return;
    }

    // Dynamically load the AdSense script if it's not already present
    if (!window.adsbygoogle) {
      const script = document.createElement('script');
      script.src =
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6651076340022803';
      script.async = true;
      script.crossOrigin = 'anonymous';
      document.head.appendChild(script);
    }

    // Initialize the ad once the script is ready
    const initializeAd = () => {
      if (window.adsbygoogle && adRef.current) {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
          setAdLoaded(true);
        } catch (err) {
          console.error('AdSense error:', err);
        }
      } else {
        // Retry after a short delay if AdSense isn't ready yet
        setTimeout(initializeAd, 200);
      }
    };

    // Add a timeout to prevent infinite loading
    const timeout = setTimeout(() => {
      initializeAd();
    }, 1000);

    return () => clearTimeout(timeout);
  }, [type, isMobile, isProduction]); // Re-run when ad type, device type, or environment changes

  // Return null for certain ad types on mobile to prevent layout issues
  if (isMobile && (type === 'vertical' || type === 'sidebar')) {
    return null;
  }

  // If not in production, show a placeholder box instead of actual ads
  if (!isProduction) {
    return (
      <Box
        sx={{
          width: '100%',
          maxWidth: adSize.maxWidth,
          height: adSize.maxHeight,
          margin: '0 auto',
          border: '1px dashed #ccc',
          backgroundColor: '#f5f5f5',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px',
          color: '#666',
          fontSize: '0.75rem',
          fontFamily: '"DM Sans", sans-serif',
          overflow: 'hidden',
        }}
      >
        <Typography variant="caption" sx={{ textAlign: 'center', padding: 1 }}>
          Ad Placeholder ({type})
          <br />
          {adSize.maxWidth} × {adSize.maxHeight}
        </Typography>
      </Box>
    );
  }

  // In production, return the actual ad
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: adSize.maxWidth,
        height: adSize.maxHeight,
        margin: '0 auto',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ins
        ref={adRef}
        className="adsbygoogle"
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
          maxWidth: adSize.maxWidth,
          maxHeight: adSize.maxHeight,
        }}
        data-ad-client="ca-pub-6651076340022803"
        data-ad-slot={getAdSlot()}
      />
    </Box>
  );
};

export default AdPlaceholder;
