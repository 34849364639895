import { defaultTheme } from 'react-admin';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#4B1591', // Darker purple (adjusted from #5B19B1)
      light: '#6827B8', // Adjusted light purple to match
      dark: '#3B1171', // Adjusted dark purple to match
    },
    secondary: {
      main: '#006631', // Dark green
      light: '#00A050', // Brighter light green
      dark: '#004F26', // Darkest green
    },
    background: {
      default: '#F5F7FA', // Keeping light background for contrast
      paper: '#FFFFFF',
      menu: '#EDE0F5', // Adjusted menu tint to match new purple
    },
    text: {
      primary: '#1B2631', // Keeping dark text for readability
      secondary: '#3B0F71', // Updated to match new primary
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          fontWeight: 600,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 4px rgba(145, 158, 171, 0.16)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          padding: '24px',
        },
      },
    },
  },
  typography: {
    fontFamily: "'Inter', 'Roboto', sans-serif",
  },
});

export default theme;
