import React, { useEffect, useState } from 'react';
import { Show, ArrayField, ChipField, SingleFieldList, useRecordContext } from 'react-admin';
import { Box, Typography, Container, Paper, Grid, useMediaQuery, useTheme } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import NewsletterSignup from './NewsletterSignup';
import { PageBreadcrumbs } from '../utils/StyledBreadcrumbs';
import AdPlaceholder from '../utils/AdPlaceholder';
import { Helmet } from 'react-helmet-async';

const BlogTitle = () => {
  const record = useRecordContext();
  return record ? <span>{record.title}</span> : '';
};

const ParallaxHeader = ({ imageUrl }) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setOffset(window.pageYOffset);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '70vh',
        width: '100%',
        overflow: 'hidden',
        mb: 2,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transform: `translateY(${offset * 0.5}px)`,
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0, 0, 0, 0.2)',
          },
        }}
      />
    </Box>
  );
};

// Right sidebar ads component
const RightSidebarAds = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'sticky',
        marginTop: '48px',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        alignItems: 'center',
        width: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
      }}
    >
      <AdPlaceholder type="vertical" />
      <AdPlaceholder type="square" />
    </Box>
  );
};

const BlogMetaTags = () => {
  const record = useRecordContext();
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaImage, setMetaImage] = useState('');
  const [metaUrl, setMetaUrl] = useState('');

  useEffect(() => {
    if (!record?.data) {
      return;
    }

    // Ensure consistent URL encoding
    const encodedTitle = encodeURIComponent(record.data.title);
    const currentUrl = `https://fantasytaco.com/blogs/${encodedTitle}/show`;

    const imageUrl = record.data.featuredImage
      ? `https://fantasytaco.com/images/blogs/${record.data.featuredImage}`
      : 'https://fantasytaco.com/light_taco.png';

    const description = record.data.content
      ? record.data.content.replace(/<[^>]*>/g, '').substring(0, 160) + '...'
      : 'Read this article on Fantasy Taco - Your premier destination for dynasty fantasy football analysis.';

    setMetaTitle(record.data.title);
    setMetaDescription(description);
    setMetaImage(imageUrl);
    setMetaUrl(currentUrl);
  }, [record?.data]);

  // Don't render anything until we have all the meta data
  if (!record?.data || !metaTitle || !metaUrl) {
    return null;
  }

  return (
    <Helmet prioritizeSeoTags={true} defer={false}>
      <title>{metaTitle} | Fantasy Taco</title>
      <meta name="description" content={metaDescription} />

      {/* Open Graph */}
      <meta property="og:type" content="article" />
      <meta property="og:title" content={`${metaTitle} | Fantasy Taco`} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:image:alt" content={metaTitle} />
      <meta property="og:site_name" content="Fantasy Taco" />
      <meta property="og:url" content={metaUrl} />

      {/* Twitter Cards */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@fantasytaco420" />
      <meta name="twitter:title" content={`${metaTitle} | Fantasy Taco`} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />
      <meta name="twitter:image:alt" content={metaTitle} />

      {/* Article specific */}
      <meta property="article:published_time" content={record.data.publishedAt} />
      <meta property="article:author" content="Fantasy Taco" />
      <meta property="article:section" content="Dynasty Fantasy Football" />
      {record.data.tags?.map((tag) => (
        <meta key={`article:tag:${tag}`} property="article:tag" content={tag} />
      ))}
    </Helmet>
  );
};

const BlogShowLayout = () => {
  const record = useRecordContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const imageUrl = record?.data?.featuredImage
    ? `/images/blogs/${record.data.featuredImage}`
    : '/light_taco.png';

  return (
    <Box sx={{ bgcolor: '#f5f5f5', minHeight: '100vh' }}>
      <ParallaxHeader imageUrl={imageUrl} />

      <Container maxWidth="lg" sx={{ pb: 8 }}>
        <Box
          sx={{
            position: 'relative',
            mb: 4,
            '&::after': {
              content: '""',
              position: 'absolute',
              left: '-2rem',
              top: '50%',
              width: '4px',
              height: '70%',
              bgcolor: 'primary.main',
              transform: 'translateY(-50%)',
              borderRadius: '2px',
              display: { xs: 'none', md: 'block' },
            },
          }}
        >
          <Typography
            variant="overline"
            sx={{
              color: 'primary.main',
              fontWeight: 600,
              letterSpacing: '0.2em',
              mb: 1,
              display: 'block',
            }}
          >
            {new Date(record?.data?.publishedAt).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            })}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '2.5rem', md: '3.8rem' },
              fontWeight: 800,
              background: 'linear-gradient(45deg, #1a237e 30%, #0097a7 90%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
              letterSpacing: '-0.02em',
              lineHeight: 1.2,
              mb: 2,
            }}
          >
            {record?.data?.title}
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <ArrayField source="tags">
              <SingleFieldList>
                <ChipField
                  source="tags"
                  size="small"
                  sx={{
                    bgcolor: 'primary.light',
                    '&:hover': { bgcolor: 'primary.main' },
                    transition: 'background-color 0.3s',
                  }}
                />
              </SingleFieldList>
            </ArrayField>
          </Box>
        </Box>

        <Box sx={{ mb: 4 }}>
          <AdPlaceholder type="horizontal" />
        </Box>

        <Grid container spacing={3}>
          {/* Main content */}
          <Grid item xs={12} md={9}>
            <Paper
              elevation={0}
              sx={{
                p: { xs: 3, md: 6 },
                borderRadius: 2,
                bgcolor: 'white',
              }}
            >
              <Box
                sx={{
                  fontSize: '1.2rem',
                  lineHeight: 1.8,
                  '& p': { mb: 2 },
                  '& h1, & h2, & h3': {
                    mt: 4,
                    mb: 2,
                    fontWeight: 700,
                  },
                  '& img': {
                    maxWidth: '100%',
                    height: 'auto',
                    borderRadius: 2,
                    my: 4,
                  },
                  '& hr': {
                    my: 4,
                    border: 'none',
                    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                  },
                  '& a': {
                    color: 'primary.main',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  },
                  '& ul, & ol': {
                    pl: 3,
                    mb: 2,
                  },
                  '& li': {
                    mb: 1,
                  },
                }}
              >
                {record?.data?.content && (
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    components={{
                      a: ({ node, ...props }) => (
                        <a
                          {...props}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: '#1976d2',
                            textDecoration: 'none',
                          }}
                        />
                      ),
                    }}
                  >
                    {record.data.content}
                  </ReactMarkdown>
                )}
              </Box>
            </Paper>

            {/* Bottom ad for all screen sizes */}
            <Box sx={{ mt: 4 }}>
              <AdPlaceholder type="horizontal" />
            </Box>

            <Box sx={{ mt: 4, mb: 4 }}>
              <NewsletterSignup title="Enjoyed this article?" buttonText="Sign Up" />
            </Box>
          </Grid>

          {/* Right sidebar with ads (only visible on desktop) */}
          <Grid item md={3}>
            <RightSidebarAds />
          </Grid>
        </Grid>

        <AdPlaceholder type="horizontal" />
      </Container>
    </Box>
  );
};

const BlogShowContent = () => {
  const record = useRecordContext();

  // Only render once we have data
  if (!record?.data) {
    return null;
  }

  return (
    <Box>
      <BlogMetaTags />
      <PageBreadcrumbs />
      <BlogShowLayout />
    </Box>
  );
};

export const BlogShow = () => {
  console.log('🚀 BlogShow mounting');

  return (
    <Show>
      <BlogShowContent />
    </Show>
  );
};
