import React from 'react';
import {
  List,
  TextField,
  DateField,
  ChipField,
  ArrayField,
  SingleFieldList,
  ShowButton,
  useListContext,
} from 'react-admin';
import { Card, CardContent, CardMedia, Typography, Grid, Box, Chip, Button } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { CreateOutlined, SportsSoccerOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { PageBreadcrumbs } from '../utils/StyledBreadcrumbs';

const BlogGrid = () => {
  const { data, isLoading } = useListContext();
  const navigate = useNavigate();
  const theme = useTheme();

  const createPreview = (content, maxLength) => {
    if (!content) return '';
    const plainText = content.replace(/<[^>]*>/g, '');
    return plainText.length > maxLength ? `${plainText.substring(0, maxLength)}...` : plainText;
  };

  if (isLoading) {
    return (
      <Box sx={{ p: { xs: 0, sm: 2 } }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Box sx={{ p: { xs: 2, sm: 4 }, textAlign: 'center' }}>
        <Typography variant="h5" color="text.secondary">
          New blog posts are coming soon!
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={0} sx={{ p: { xs: 0, sm: 2 }, justifyContent: 'flex-start' }}>
      {data.map((blog) => (
        <Grid
          item
          xs={12}
          sx={{ maxWidth: '1000px', mx: 'auto', mb: { xs: 0, sm: 3 } }}
          key={blog.id}
        >
          <Card
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              transition: 'all 0.3s ease',
              backgroundColor: '#ffffff',
              borderRadius: { xs: 0, sm: '12px' },
              overflow: 'hidden',
              border: { xs: 'none', sm: '1px solid rgba(0,0,0,0.08)' },
              borderBottom: '1px solid rgba(0,0,0,0.08)',
              '&:hover': {
                transform: { xs: 'none', sm: 'translateY(-4px)' },
                boxShadow: { xs: 'none', sm: '0 12px 24px rgba(0,0,0,0.15)' },
                cursor: 'pointer',
                '& img': {
                  transform: 'scale(1.05)',
                },
              },
            }}
            onClick={() => navigate(`/blogs/${encodeURIComponent(blog.title)}/show`)}
          >
            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
              <CardMedia
                component="img"
                sx={{
                  width: { xs: '100%', sm: '320px' },
                  height: { xs: '200px', sm: '280px' },
                  objectFit: 'cover',
                  transition: 'transform 0.5s ease',
                }}
                image={
                  blog.featuredImage ? `/images/blogs/${blog.featuredImage}` : '/light_taco.png'
                }
                alt={blog.title}
              />
            </Box>
            <CardContent
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                p: { xs: 2, sm: 3 },
                backgroundColor: '#ffffff',
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: '1.25rem', sm: '1.5rem' },
                  letterSpacing: '-0.02em',
                  color: '#1a1a1a',
                  mb: 1,
                  lineHeight: 1.3,
                }}
              >
                {blog.title}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  mb: { xs: 1, sm: 2 },
                  color: '#666',
                  fontWeight: 500,
                  textTransform: 'uppercase',
                  letterSpacing: '0.05em',
                  fontSize: '0.75rem',
                }}
              >
                {blog.publishedAt && format(new Date(blog.publishedAt), 'MMM d, yyyy')}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mb: { xs: 2, sm: 3 },
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: {
                    xs: 3,
                    sm: 4,
                    md: 5,
                  },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  lineHeight: 1.6,
                  color: '#4a4a4a',
                  fontSize: { xs: '0.9rem', sm: '0.95rem' },
                }}
              >
                {blog.content?.replace(/<[^>]*>/g, '')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  flexWrap: 'wrap',
                  mt: 'auto',
                  mx: { xs: -0.5, sm: 0 },
                }}
              >
                {blog.tags?.map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    size="small"
                    sx={{
                      backgroundColor: '#f0f2f5',
                      color: '#2e7d32',
                      fontWeight: 600,
                      textTransform: 'uppercase',
                      fontSize: '0.7rem',
                      letterSpacing: '0.03em',
                      height: { xs: '24px', sm: '32px' },
                      '&:hover': {
                        backgroundColor: '#e1e4e8',
                      },
                    }}
                  />
                ))}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export const BlogList = () => (
  <Box sx={{ margin: { xs: '-16px', sm: 0 } }}>
    <PageBreadcrumbs />
    <List
      sort={{ field: 'publishedAt', order: 'DESC' }}
      perPage={1000}
      component="div"
      sx={{
        backgroundColor: '#f8f9fa',
        minHeight: '100vh',
      }}
      pagination={false}
      actions={false}
      empty={
        <Box
          sx={{
            p: { xs: 4, sm: 8 },
            textAlign: 'center',
            backgroundColor: '#ffffff',
            borderRadius: { xs: 0, sm: '16px' },
            boxShadow: { xs: 'none', sm: '0 4px 24px rgba(0,0,0,0.06)' },
            minHeight: 'calc(100vh - 200px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            m: { xs: 0, sm: 3 },
            '& svg': {
              animation: 'float 3s ease-in-out infinite',
            },
            '@keyframes float': {
              '0%, 100%': {
                transform: 'translateY(0)',
              },
              '50%': {
                transform: 'translateY(-10px)',
              },
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: '600px',
              width: '100%',
              px: 3,
            }}
          >
            <SportsSoccerOutlined
              sx={{
                fontSize: 100,
                color: '#2e7d32',
                mb: 4,
                opacity: 0.9,
                background: 'linear-gradient(45deg, #f3f3f3, #fff)',
                borderRadius: '50%',
                p: 3,
                boxShadow: '0 8px 24px rgba(46,125,50,0.15)',
              }}
            />
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
                mb: 2,
                fontSize: { xs: '1.8rem', sm: '2.2rem' },
                letterSpacing: '-0.02em',
                background: 'linear-gradient(45deg, #1a1a1a, #2e7d32)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              No Blog Posts Yet
            </Typography>
            <Typography
              variant="body1"
              sx={{
                maxWidth: '500px',
                mx: 'auto',
                lineHeight: 1.8,
                fontSize: '1.1rem',
                color: '#666',
                fontWeight: 500,
                px: 2,
              }}
            >
              New and exciting blog posts are coming soon. Stay tuned!
            </Typography>
          </Box>
        </Box>
      }
    >
      <BlogGrid />
    </List>
  </Box>
);
