import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { Title } from 'react-admin';
import NewsletterSignup from './NewsletterSignup';
import { PageBreadcrumbs } from '../utils/StyledBreadcrumbs';

const MockDraftList = () => {
  return (
    <>
      <PageBreadcrumbs />
      <Box sx={{ margin: 'auto', px: 3, pb: 4 }}>
        <Title title="Mock Drafts" />

        <Card elevation={3} sx={{ mt: 4, mb: 4, borderRadius: 2 }}>
          <CardContent sx={{ textAlign: 'center', py: 6 }}>
            <Typography variant="h1" sx={{ mb: 3 }}>
              🚧
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: 'bold', color: 'primary.main' }}
            >
              Coming Soon!
            </Typography>
            <Typography variant="body1" sx={{ mb: 0, mx: 'auto' }}>
              We're working hard to bring you a different type of mock drafting experience.
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, mx: 'auto' }}>
              Here's what's coming:
            </Typography>
            <Box sx={{ textAlign: 'left', mx: 'auto' }}>
              <Typography component="ul" sx={{ listStyle: 'none', pl: 0 }}>
                {[
                  '🎮 Practice drafting with real people where each person drafts for a team in your league',
                  '📍 Draft with your real pick positions',
                  '⚙️ Import your Sleeper league settings automatically',
                  '🔄 Run unlimited mock drafts to perfect your strategy',
                ].map((item, index) => (
                  <Typography
                    component="li"
                    key={index}
                    sx={{
                      mb: 1.5,
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '1.1rem',
                    }}
                  >
                    <Box component="span" sx={{ mr: 2 }}>
                      {item.split(' ')[0]}
                    </Box>
                    <span>{item.split(' ').slice(1).join(' ')}</span>
                  </Typography>
                ))}
              </Typography>
            </Box>
          </CardContent>
        </Card>

        <NewsletterSignup
          title="Be the First to Know! 🎉"
          description="Sign up to get notified when our mock draft simulator launches. Get early access and exclusive updates!"
          buttonText="Notify Me"
        />
      </Box>
    </>
  );
};

export default MockDraftList;
