import React, { useState } from 'react';
import {
  List,
  SearchInput,
  useListContext,
  TopToolbar,
  CreateButton,
  useCreatePath,
  useListController,
} from 'react-admin';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import AdPlaceholder from '../utils/AdPlaceholder';
import { PageBreadcrumbs } from '../utils/StyledBreadcrumbs';
import { mapArchetypeToIcon } from '../utils/archetypeUtils';
import LoadingScreen from '../utils/LoadingScreen';

const ListActions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TopToolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: { xs: '8px', sm: '16px' },
        marginBottom: { xs: '8px', sm: '16px' },
      }}
    >
      <CreateButton
        label={isMobile ? 'Add GM' : 'Add New GM'}
        sx={{
          color: '#111827',
          fontWeight: 500,
          fontSize: { xs: '0.8rem', sm: '0.875rem' },
          padding: { xs: '6px 12px', sm: '8px 16px' },
          minWidth: { xs: 'auto', sm: '120px' },
          whiteSpace: 'nowrap',
          display: 'flex',
          visibility: 'visible',
          '& .MuiButton-startIcon': {
            marginRight: { xs: '4px', sm: '8px' },
          },
        }}
      />
    </TopToolbar>
  );
};

const Empty = () => {
  const { filterValues } = useListContext();
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h6" paragraph>
        No GMs found
      </Typography>
      {filterValues?.q && (
        <Typography variant="body2">No results found for "{filterValues.q}"</Typography>
      )}
    </Box>
  );
};

const filters = [
  <SearchInput
    source="display_name"
    alwaysOn
    placeholder="Search General Managers..."
    sx={{
      '& .MuiInputBase-root': {
        width: '100%',
        minWidth: '320px',
        maxWidth: '420px',
        margin: '0 auto',
        background: 'transparent',
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
        },
        '&:hover fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.2) !important',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.3) !important',
        },
      },
      '& .MuiInputBase-input': {
        height: '40px',
        padding: '0 16px',
      },
      display: 'flex',
      justifyContent: 'center',
      mt: { xs: 3, sm: 4 },
      mb: 3,
    }}
  />,
];

const UserCard = ({ record }) => {
  const navigate = useNavigate();

  if (!record) return null;

  return (
    <Card
      sx={{
        height: '100%',
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: 2,
        },
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '12px',
        overflow: 'hidden',
      }}
    >
      <CardActionArea
        onClick={() => navigate(`/usernames/${record.display_name}/show`)}
        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <Box
          sx={{
            position: 'relative',
            height: { xs: 180, sm: 220 },
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f8f9fa',
          }}
        >
          <img
            src={
              record.avatar_url
                ? `https://sleepercdn.com/avatars/${record.avatar_url}`
                : '/light_taco.png'
            }
            alt={record.display_name}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
              pt: 3,
              pb: 1,
              px: 1.5,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontSize: { xs: '0.95rem', sm: '1.1rem' },
                fontWeight: 600,
                fontFamily: '"DM Sans", sans-serif',
                color: 'white',
                textShadow: '0 1px 2px rgba(0,0,0,0.2)',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {record.display_name}
            </Typography>
            {record.sleeperUsername && (
              <Typography
                variant="body2"
                sx={{
                  color: 'rgba(255,255,255,0.8)',
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  textShadow: '0 1px 2px rgba(0,0,0,0.2)',
                }}
              >
                @{record.sleeperUsername}
              </Typography>
            )}
          </Box>
        </Box>

        <CardContent
          sx={{
            p: { xs: 1.5, sm: 2 },
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            bgcolor: 'white',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1.5,
              p: 1.5,
              borderRadius: '10px',
              bgcolor: '#f3f4f6',
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '1.3rem', sm: '1.4rem' },
                lineHeight: 1,
              }}
            >
              {mapArchetypeToIcon(record.archetype)}
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography
                sx={{
                  fontSize: { xs: '0.8rem', sm: '0.85rem' },
                  fontWeight: 600,
                  color: '#111827',
                  lineHeight: 1.2,
                }}
              >
                {record.archetype || 'N/A'}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '0.7rem', sm: '0.75rem' },
                  color: '#6B7280',
                  mt: 0.3,
                }}
              >
                {record.leagueCount} {record.leagueCount === 1 ? 'League' : 'Leagues'}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const UsernamesListContent = () => {
  const { data } = useListContext();
  const navigate = useNavigate();
  const createPath = useCreatePath();

  if (!data) return null;

  return (
    <Grid
      container
      spacing={{ xs: 1, sm: 2, md: 3 }}
      sx={{
        width: '100%',
        margin: '0 auto',
        minHeight: '200px',
        maxWidth: '100%',
        boxSizing: 'border-box',
        overflow: 'hidden',
      }}
    >
      {data.map((record, index) => (
        <React.Fragment key={record.id}>
          {/* Add horizontal ad after every 8 users */}
          {index > 0 && index % 8 === 0 && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  my: { xs: 1, sm: 2 },
                  width: '100%',
                  maxWidth: '100%',
                  overflow: 'hidden',
                }}
              >
                <AdPlaceholder type="horizontal" />
              </Box>
            </Grid>
          )}
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={3}
            sx={{
              minWidth: { xs: '50%', sm: '300px' },
            }}
          >
            <UserCard record={record} />
          </Grid>
        </React.Fragment>
      ))}

      {/* Only show Add New GM and final ad if not searching */}
      {!data.filter?.q && (
        <>
          <Grid item xs={6} sm={6} md={4} lg={3} sx={{ minWidth: { xs: '50%', sm: '300px' } }}>
            <Card
              sx={{
                height: '100%',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 2,
                },
              }}
            >
              <CardActionArea
                onClick={() => navigate(createPath({ resource: 'usernames', type: 'create' }))}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: { xs: 1.5, sm: 3 },
                }}
              >
                <Box
                  sx={{
                    height: { xs: 140, sm: 160 },
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#f8f9fa',
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{ color: '#dee2e6', fontSize: { xs: '3rem', sm: '4rem' } }}
                  >
                    +
                  </Typography>
                </Box>
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontSize: { xs: '0.9rem', sm: '1rem' },
                      fontWeight: 500,
                      textAlign: 'center',
                    }}
                  >
                    Add New GM
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mt: 1, textAlign: 'center', fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
                  >
                    Can't find who you're looking for? Add them to our database.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={3} sx={{ minWidth: { xs: '50%', sm: '300px' } }}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '100%',
                overflow: 'hidden',
              }}
            >
              <AdPlaceholder type="square" />
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const ProfileWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  margin: '0 auto',
  padding: 0,
  position: 'relative',
  background: '#fff',
  borderBottom: '1px solid #E0E0E0',
  overflow: 'hidden',
  boxSizing: 'border-box',
  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
  },
}));

// Update ContentLayout to use width constraints
const ContentLayout = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing(2),
  padding: { xs: theme.spacing(1), sm: theme.spacing(2) },
  width: '100%',
  maxWidth: '100%',
  margin: '0 auto',
  overflow: 'hidden',
  boxSizing: 'border-box',
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '300px 1fr',
    padding: theme.spacing(3),
    maxWidth: '100%',
    gap: theme.spacing(3),
  },
}));

export const UsernameList = () => {
  // const [showStartSit, setShowStartSit] = useState(false);
  const { data } = useListController();
  if (!data) {
    return (
      <Box>
        <ProfileWrapper sx={{ height: '100vh' }}>
          <ContentLayout>
            <LoadingScreen message="Loading General Managers..." />
          </ContentLayout>
        </ProfileWrapper>
      </Box>
    );
  }

  return (
    <>
      <PageBreadcrumbs />
      <Box
        sx={{
          px: { xs: 1, sm: 2, md: 3 },
          pt: { xs: 2, sm: 3 },
          pb: 4,
          maxWidth: '100vw',
          width: '100%',
          overflow: 'hidden',
          boxSizing: 'border-box',
        }}
      >
        <List
          actions={<ListActions />}
          filters={filters}
          empty={<Empty />}
          perPage={100}
          sort={{ field: 'created_at', order: 'DESC' }}
          filterDefaultValues={{ display_name: '' }}
          pagination={false}
          component="div"
          sx={{
            '& .RaList-main': {
              width: '100%',
              background: 'transparent',
              boxShadow: 'none',
              '& .MuiToolbar-root': {
                background: 'transparent',
                marginTop: { xs: 3, sm: 4 },
                minHeight: 0,
                padding: 0,
              },
              '& .MuiCardContent-root': {
                background: 'transparent',
                padding: 0,
              },
            },
            '& .RaList-content': {
              padding: 0,
              background: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <UsernamesListContent />
        </List>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            my: { xs: 1, sm: 2 },
            width: '100%',
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          <AdPlaceholder type="horizontal" />
        </Box>
      </Box>
    </>
  );
};
